/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductToRepriceStatusFilter } from '../models/ProductToRepriceStatusFilter';
import type { Reprice } from '../models/Reprice';
import type { SearchProductToRepriceFilter } from '../models/SearchProductToRepriceFilter';
import { request as __request } from '../core/request';

export class RepriceRestService {
  /**
   * technical service to execute and force the price load job
   * technical service to execute and force the price load job
   * @returns any Accepted
   * @throws ApiError
   */
  public static async repriceLoadRepriceCsv(): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/reprice/loadRepriceCsv`,
      errors: {
        400: `bad input parameter`,
        500: `Internal server error`,
      },
    });
    return result.body;
  }

  /**
   * SA have to to reprice upc
   * By passing in the appropriate options, you can search UPC for reprice. <br><b> [now timezone - daysToSubract] <= PRICE START_DATE < [now timezone + daysToSum]
   * @returns Reprice OK
   * @returns any No content
   * @throws ApiError
   */
  public static async repriceSearchProductToReprice({
    requestBody,
  }: {
    requestBody: SearchProductToRepriceFilter;
  }): Promise<Reprice | any> {
    const result = await __request({
      method: 'POST',
      path: `/reprice/searchProductToReprice`,
      body: requestBody,
      errors: {
        400: `bad input parameter`,
        500: `Internal server error`,
      },
    });
    return result.body;
  }

  /**
   * Manage Status reprice.
   * Manage Status reprice. <br><b> This service calls /reprice/searchProductToReprice</b>
   * @returns Reprice OK
   * @returns any No content
   * @throws ApiError
   */
  public static async repriceRepricePrintStatus({
    requestBody,
  }: {
    requestBody: ProductToRepriceStatusFilter;
  }): Promise<Reprice | any> {
    const result = await __request({
      method: 'PUT',
      path: `/reprice/repricePrintStatus`,
      body: requestBody,
      errors: {
        400: `bad input parameter`,
        500: `Internal server error`,
      },
    });
    return result.body;
  }

  /**
   * BE technical service to empty PRICE_CATALOG_BACKUP table
   * technical service to deletes from the PRICE_CATALOG_BACKUP table all records whose TF_CREATION_DATE column is <  (NOW - days)
   * @returns any Rows deleted 10
   * @throws ApiError
   */
  public static async repriceDeletePriceCatalogBackup({
    days,
  }: {
    /** number of days that are subtracted from the request date **/
    days: any;
  }): Promise<any> {
    const result = await __request({
      method: 'DELETE',
      path: `/reprice/deletePriceCatalogBackup`,
      query: {
        days: days,
      },
      errors: {
        400: `days must be between 0 and 999`,
        500: `Internal server error`,
      },
    });
    return result.body;
  }
}
