import React, { FC, useEffect, useState } from 'react';
import { AppRoutes } from '@/app/routers';
import SearchBarLight from '@/components/layout/SearchBar/SearchBarLight';
import { PageLoader } from '@/components/ui/PageLoader';
import { Typography } from '@/components/ui/Typography';
import { useHistory } from 'react-router-dom';
import { AlertSnackbar } from '@/components/ui/AlertSnackbar';
import { UIButtonWithIcon } from '@/components/ui/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useTranslation } from 'react-i18next';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { DateTime } from 'luxon';
import {
  ApiError,
  LoadStoresRestService,
  MigrationStatusPruduction,
} from '@/api/receive';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import {
  StyledBrandsList,
  StyledDownloadWrapper,
  StyledFilterWrapper,
  StyledListGridHeader,
  StyledPageWrapper,
  StyledSelectAllLink,
} from './style';
import PageStoreMigratorRow from './PageStoreMigratorRow';
import { PaginationList } from '@/components/layout/PaginationList';
import { STOREMIGRATORSTATUS } from '@/types/enum';
import { sortByKey } from '@/utils';

const PageVasStore: FC = () => {
  const history = useHistory();
  const [storeCodeFilter, setStoreCodeFilter] = useState<string>('');
  const [alertSnackbarIsVisible, setAlertSnackbarVisibility] =
    useState<boolean>(false);
  const [errorSnackbarIsVisible, setErrorSnackbarVisibility] =
    useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isStoreSelected, setStoreSelected] = useState<boolean>(false);
  const [items, setItems] = useState<MigrationStatusPruduction[]>([]);
  const [filenamesSelected, setFilenamesSelected] = useState<string[]>([]);
  const [errorMigrator, setErrorMigrator] = useState<ApiError | undefined>();
  const [listCurrentPage, setListCurrentPage] = useState<number>(1);

  const { t } = useTranslation();

  const initState = (withLoad?: boolean): void => {
    setFilenamesSelected([]);
    setStoreSelected(false);
    withLoad && loadMigrations();
  };

  const selectAllClickHandler = (): void => {
    setStoreSelected(prevState => !prevState);
    if (!isStoreSelected) {
      setFilenamesSelected(
        items
          .filter(({ status }) => status === STOREMIGRATORSTATUS.WAITING)
          .map(({ fileName }) => fileName)
      );
    } else {
      setFilenamesSelected([]);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setStoreCodeFilter(e.currentTarget.value);
  };

  const onDownloadClick = async (storeCode: string): Promise<void> => {
    if (storeCode) {
      try {
        const { csvFile, nameFile } =
          await LoadStoresRestService.loadstoresExtractErrorDetails({
            storeCode: storeCode,
          });

        const linkSource = `data:application/csv;base64,${csvFile}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = nameFile;
        downloadLink.click();
      } catch (err) {
        setErrorMigrator(err as ApiError);
      }
    }
  };

  const onDownloadsClick = async (): Promise<void> => {
    try {
      const { csvFile, nameFile } =
        await LoadStoresRestService.loadstoresExtractAllErrors();

      const linkSource = `data:application/zip;base64,${csvFile}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = nameFile;
      downloadLink.click();
    } catch (err) {
      setErrorMigrator(err as ApiError);
    }
  };

  const onBackClick = (): void => {
    history.push(AppRoutes.STORE_MIGRATOR);
  };

  const onSaveClick = async (): Promise<void> => {
    try {
      await LoadStoresRestService.loadstoresLoadProduction({
        requestBody: { fileNames: filenamesSelected },
      });
      initState(true);
    } catch (err) {
      setErrorMigrator(err as ApiError);
      initState();
    }
  };

  const onCheckboxClick = (fileName: string): void => {
    setFilenamesSelected(prevState => {
      const filenames = prevState;

      if (filenames.includes(fileName)) {
        return prevState.filter(filename => filename !== fileName);
      }

      return [...prevState, fileName];
    });
  };

  const loadMigrations = async (): Promise<void> => {
    setLoading(true);
    try {
      const migrations = await LoadStoresRestService.loadstoresRecapMigration();
      const status = [
        STOREMIGRATORSTATUS.ERROR,
        STOREMIGRATORSTATUS.WAITING,
        STOREMIGRATORSTATUS.LOADING,
        STOREMIGRATORSTATUS.COMPLETED,
      ];
      if (migrations) {
        setItems(
          sortByKey<MigrationStatusPruduction>(
            migrations.migrations as MigrationStatusPruduction[],
            'status',
            status,
            (a, b) =>
              DateTime.fromISO(a.lastUpload).toMillis() -
              DateTime.fromISO(b.lastUpload).toMillis()
          )
        );
      }
    } catch (err) {
      setErrorMigrator(err as ApiError);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadMigrations();
  }, []);

  useEffect(() => {
    if (typeof errorMigrator !== 'undefined') {
      setErrorSnackbarVisibility(true);
    } else {
      setErrorSnackbarVisibility(false);
    }
  }, [errorMigrator]);

  return (
    <>
      <StyledPageWrapper>
        <StyledFilterWrapper>
          <SearchBarLight
            label=""
            value={storeCodeFilter}
            placeholder={t('profiling.filterByStoreCode')}
            disabled={false}
            onSearch={(): void => {}}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              onChange(e)
            }
            loading={false}
            hideButton={true}
          />
          <StyledDownloadWrapper>
            <UIButtonWithIcon
              disabled={
                items.filter(({ status }) => status === 'Error').length < 2
              }
              label={t('page.store_migrator.download_errors')}
              startIcon={<GetAppIcon />}
              onClick={onDownloadsClick}
            />
          </StyledDownloadWrapper>
        </StyledFilterWrapper>
        <StyledFilterWrapper>
          <div>
            <StyledSelectAllLink onClick={selectAllClickHandler}>
              {isStoreSelected
                ? t('showChips.selectAll.1')
                : t('showChips.selectAll.0')}
            </StyledSelectAllLink>
            &nbsp;&nbsp;
            <Typography>
              {t('item_selected', { selected: filenamesSelected.length })}
            </Typography>
          </div>
          <StyledDownloadWrapper>
            <UIButtonWithIcon
              label={t('refresh')}
              startIcon={<RefreshIcon />}
              onClick={(): void => initState(true)}
            />
          </StyledDownloadWrapper>
        </StyledFilterWrapper>
        <StyledBrandsList>
          <StyledListGridHeader>
            <Typography font="heavy">&nbsp;</Typography>
            <Typography font="heavy">
              {t('page.store_migrator.last_updated')}
            </Typography>
            <Typography font="heavy">
              {t('page.store_migrator.store_code')}
            </Typography>
            <Typography font="heavy" align="right">
              {t('page.store_migrator.status')}
            </Typography>
          </StyledListGridHeader>
          {isLoading ? (
            <PageLoader />
          ) : (
            <PaginationList
              setCurrPage={setListCurrentPage}
              currPage={listCurrentPage}
              pageSize={25}
              data={items.filter(({ storeCode }) =>
                storeCode?.toLowerCase().includes(storeCodeFilter.toLowerCase())
              )}
              renderItem={(item: MigrationStatusPruduction): JSX.Element => (
                <PageStoreMigratorRow
                  item={item}
                  key={item.fileName}
                  onCheckboxClick={onCheckboxClick}
                  onDownloadClick={onDownloadClick}
                  checked={filenamesSelected.includes(item.fileName)}
                />
              )}
            />
          )}
        </StyledBrandsList>
        <CTAContainer
          type="TWO_BUTTONS"
          onClick={(): Promise<void> => onSaveClick()}
          onBackClick={(): void => onBackClick()}
          mainButtonLabel={t('page.store_migrator.load')}
          disabledMainAction={filenamesSelected.length < 1}
        />
      </StyledPageWrapper>

      <AlertSnackbar
        open={alertSnackbarIsVisible}
        setIsOpen={setAlertSnackbarVisibility}
        message={''}
      />

      <ErrorSnackbar
        open={errorSnackbarIsVisible}
        setIsOpen={setErrorSnackbarVisibility}
        errorMessage={errorMigrator?.body || t('error.something_went_wrong')}
      />
    </>
  );
};

export default PageVasStore;
