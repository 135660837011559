import { FC, useState, useEffect, useMemo } from 'react';
import { DateTime } from 'luxon';
import { useSelector } from '@/hooks/useSelector';
import { useAppDispatch } from '@/app/store';
import styled from 'styled-components';
import { useBreadcrumbs } from '@/hooks/useBreadcrumbs';
import { useTranslation } from 'react-i18next';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { Typography } from '@/components/ui/Typography';

import { Checkbox, Container, FormControlLabel } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { UIButtonWithIcon } from '@/components/ui/Button';
import { UIBox } from '@/components/ui/Box';
import { UIListHeader } from '@/components/ui/ListHeader';
import { AlertSnackbar } from '@/components/ui/AlertSnackbar';

import { Tabs } from '@/components/layout/Tabs';

import { PageLoader } from '@/components/ui/PageLoader';
import { ModalRepriceItems } from '@/components/layout/ModalRepriceItems';
import { ModalLoading } from '@/components/layout/ModalLoading';
import {
  getRepriceBrands,
  printRepriceProducts,
  setCurrentDateSlice,
} from '@/features/repricing/repriceSlice';
import { RepriceBrand, RepriceUpc } from '@/api/receive';
import { ModalSelectPrinters } from '@/components/layout/ModalSelectPrinters';
import { useHistory } from 'react-router';
import { RepriceBrandList } from '@/components/layout/RepriceBrandList';
import SearchBarLight from '@/components/layout/SearchBar/SearchBarLight';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { palette } from '@/theme';

//#region - Styled Components

const StyledContainer = styled(Container)`
  margin-bottom: 150px;
`;

const StyledPaginationWrapper = styled('div')`
  width: 100%;
  margin-top: 24px;
  padding: 0;

  & > div {
    padding: 0;
    margin-bottom: 100px;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: 8px;
`;

export const StyledHeaderList = styled(UIListHeader)`
  &&& {
    padding: 0;
  }
`;

export const StyledSearchBar = styled(SearchBarLight)`
  &&& {
    padding: 0;
  }
`;

const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  margin-right: 16px;
  height: 20px;
  width: 20px;
`;
//#endregion

const PageRepricing: FC = () => {
  const totalUpcs = 25;
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { data, currentDate, dataIsLoading, printIsLoading, printHasError } =
    useSelector(state => state.reprice);

  const { store } = useSelector(state => state.currentStore);
  const printers = useSelector(
    state => state.currentStore.store?.printers || []
  );

  const [checkedUpcs, setCheckedUpcs] = useState<string[]>([]);
  const [selectedPrinter, setSelectedPrinter] = useState<string>();
  const [searchedUpcCode, setSearchedUpcCode] = useState<string>();
  const [isPrintStarted, setPrintStatus] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [noPrinterAvailable, setNoPrinterAvailable] = useState<boolean>(false);
  const [filteredList, setFilteredList] = useState<RepriceBrand[]>([]);
  const [upcPrintQuantity, setUpcPrintQuantity] = useState<Map<string, number>>(
    new Map()
  );
  const [repriceBrand, setRepriceBrand] = useState<RepriceBrand>({
    brandName: '',
    upcs: [],
  });

  const [alertSnackbarIsVisible, setAlertSnackbarVisibility] =
    useState<boolean>(false);
  const [isBrandModalVisible, setBrandModalVisibility] =
    useState<boolean>(false);
  const [isSelectPrintersModalVisible, setSelectPrintersModalVisibility] =
    useState<boolean>(false);
  const [filterEpcWithoutUpc, setFilterEpcWithoutUpc] = useState<boolean>(true);

  useBreadcrumbs([
    {
      title: t('page.repricing'),
    },
    {
      title: t('page.repricing_list'),
    },
  ]);

  const dates = useMemo(
    () =>
      data.dateRepriceList &&
      data.dateRepriceList.map(({ date }) =>
        String(
          DateTime.fromFormat(String(date), 'yyyyMMdd').toFormat('yyyy/MM/dd')
        )
      ),
    [data.dateRepriceList]
  );

  useEffect(() => {
    const curDate = currentDate;
    setSelectedTab(
      dates && dates.findIndex(d => d === curDate) > 0
        ? dates?.findIndex(d => d === curDate)
        : selectedTab
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates, data, currentDate]);

  useEffect(() => {
    if (printers.length === 0) {
      setNoPrinterAvailable(true);
    }
  }, [printers.length]);

  const brandsList = useMemo(() => {
    const brands = data.dateRepriceList;

    if (brands) {
      const brandIndex = brands.findIndex((_, index) => selectedTab === index);

      if (brandIndex !== -1) {
        setCheckedUpcs([]);

        if (filterEpcWithoutUpc) {
          return brands[brandIndex].brandList
            ?.map(item => ({
              ...item,
              upcs: item.upcs.filter(({ numberItems }) => numberItems > 0),
            }))
            .filter(({ upcs }) => upcs.length > 0);
        }

        return brands[brandIndex].brandList;
      }
    }

    return [];
  }, [data.dateRepriceList, selectedTab, filterEpcWithoutUpc]);

  useEffect(() => {
    if (searchedUpcCode && searchedUpcCode.length > 3) {
      const filtered = brandsList.map(brand => ({
        ...brand,
        upcs: brand.upcs.filter(({ upcCode }) =>
          upcCode.toLowerCase().includes(searchedUpcCode.toLowerCase())
        ),
      }));

      setFilteredList(filtered.filter(({ upcs }) => upcs.length > 0));
    } else {
      setFilteredList(brandsList);
    }
  }, [brandsList, searchedUpcCode]);

  useEffect(() => {
    dispatch(getRepriceBrands());
  }, [dispatch]);

  useEffect(() => {
    if (printHasError) {
      setAlertSnackbarVisibility(true);
    }
  }, [printHasError]);

  useEffect(() => {
    if (isPrintStarted) {
      const products =
        brandsList &&
        brandsList
          .flatMap(({ upcs }) => upcs)
          .filter(({ upcCode }) => checkedUpcs.includes(upcCode));

      if (products) {
        (async (): Promise<void> => {
          try {
            await dispatch(
              printRepriceProducts({
                products,
                upcPrintQuantity,
                selectedPrinter,
              })
            ).unwrap();

            setPrintStatus(false);
            setCheckedUpcs([]);
            setSelectedPrinter(undefined);
          } catch (err) {
            setAlertSnackbarVisibility(true);
          }
        })();
      }
    }
    // ? Disabled for possibile unwanted side effects 😦
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrintStarted, printRepriceProducts, setPrintStatus]);

  const onPrintClick = (): void => {
    if (store?.storeCode) {
      if (store.printers) {
        if (store.printers.length > 1) {
          setSelectPrintersModalVisibility(true);
        } else {
          setPrintStatus(true);
        }
      } else {
        setPrintStatus(true);
      }
    }
  };

  const onUpdateClick = async (): Promise<void> => {
    setCheckedUpcs([]);
    setSearchedUpcCode('');
    await dispatch(getRepriceBrands());
  };

  const onSeeItemsClick = (brandName: string, upcs: RepriceUpc[]): void => {
    setBrandModalVisibility(true);
    setRepriceBrand({
      brandName,
      upcs,
    });
  };

  const onTabChange = (index: number): void => {
    setSelectedTab(index);
    setSearchedUpcCode('');

    if (currentDate) {
      dispatch(setCurrentDateSlice(dates?.[index] || ''));
    }
  };

  if (dataIsLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <ModalLoading open={!!printIsLoading} />
      <ModalSelectPrinters
        open={isSelectPrintersModalVisible}
        onClose={(): void => setSelectPrintersModalVisibility(false)}
        setPrintStatus={setPrintStatus}
        selectedPrinter={selectedPrinter}
        setSelectedPrinter={setSelectedPrinter}
      />
      <StyledContainer>
        <UIBox justifyContent="space-between">
          <Typography font="heavy" as="h1" margin="30px 0 23px">
            {t('reprice.click_on_date')}
          </Typography>
          <UIButtonWithIcon
            label={t('refreshPriceList')}
            startIcon={<RefreshIcon />}
            onClick={onUpdateClick}
          />
        </UIBox>
        <Tabs
          values={dates!}
          selectedTab={selectedTab}
          onTabChange={onTabChange}
        />
        <UIBox mt={2} mb={2} justifySelf="start">
          <StyledSearchBar
            label=""
            hideButton
            disabled={false}
            loading={false}
            placeholder={t('cycleCount.filter.UPC')}
            value={searchedUpcCode}
            onSearch={(): void => {}}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              setSearchedUpcCode(e.target.value)
            }
          />
        </UIBox>
        <StyledHeaderList
          padding="0 21px"
          text={t('item_selected', {
            selected: checkedUpcs.length,
          })}
          extraText={t('itemsFound', {
            itemsFound:
              brandsList
                ?.map(({ upcs }) => upcs)
                .flat()
                .reduce((prev, curr) => {
                  if (curr) {
                    return prev + curr.numberItems;
                  }
                  return prev;
                }, 0) || 0,
          })}
          middleComponent={
            <StyledFormControlLabel
              label={t('reprice.show_upc.check')}
              control={
                <Checkbox
                  checked={filterEpcWithoutUpc}
                  onChange={(): void =>
                    setFilterEpcWithoutUpc(!filterEpcWithoutUpc)
                  }
                />
              }
            />
          }
        />
        <UIBox
          mt={-1}
          alignItems="center"
          visibility={checkedUpcs.length > 49 ? 'visible' : 'hidden'}
        >
          <StyledErrorOutlineIcon htmlColor={palette.colors.yellow} />
          <Typography size="sm">
            {t('reprice.printingIssuesMessage')}
          </Typography>
        </UIBox>
        <ModalRepriceItems
          brandName={repriceBrand.brandName}
          open={isBrandModalVisible}
          onClose={(): void => setBrandModalVisibility(false)}
          repriceUpcs={repriceBrand.upcs.reduce(
            (arr: RepriceUpc[], el: RepriceUpc): RepriceUpc[] => {
              if (
                (el.firstPrice?.price && Number(el.firstPrice.price) === 0) ||
                (el.secondPrice?.price && Number(el.secondPrice.price) === 0)
              ) {
                return [
                  ...arr,
                  {
                    ...el,
                    firstPrice:
                      el.firstPrice && Number(el.firstPrice.price) === 0
                        ? el.firstPrice
                        : undefined,
                    secondPrice:
                      el.secondPrice?.price &&
                      Number(el.secondPrice.price) === 0
                        ? el.secondPrice
                        : undefined,
                  },
                ];
              }

              return arr;
            },
            [] as RepriceUpc[]
          )}
        />
        <StyledPaginationWrapper>
          {filteredList && filteredList.length > 0 ? (
            <>
              {filteredList.map((brand, index) => (
                <RepriceBrandList
                  key={index}
                  totalUpcs={totalUpcs}
                  checkedUpcs={checkedUpcs}
                  onSeeItemsClick={onSeeItemsClick}
                  setCheckedUpcs={setCheckedUpcs}
                  setUpcPrintQuantity={setUpcPrintQuantity}
                  brand={brand}
                  selectedTab={selectedTab}
                />
              ))}
            </>
          ) : (
            <>
              <UIBox
                mt={5}
                width="100%"
                alignItems="center"
                justifyContent="center"
              >
                <Typography font="heavy">{t('noResultsFound')}</Typography>
              </UIBox>
            </>
          )}
        </StyledPaginationWrapper>
        <CTAContainer
          type="PRINT"
          label={t('item_selected', {
            selected: checkedUpcs.length,
          })}
          onClick={onPrintClick}
          onBackClick={(): void => history.goBack()}
          disabledMainAction={checkedUpcs.length === 0 || printers.length === 0}
        />
      </StyledContainer>

      <AlertSnackbar
        open={alertSnackbarIsVisible}
        setIsOpen={setAlertSnackbarVisibility}
        autoHideDuration={5000}
        message={printHasError?.body?.message}
      />

      <AlertSnackbar
        open={noPrinterAvailable}
        setIsOpen={setNoPrinterAvailable}
        message={t('error.noPrinterAvailable')}
      />
    </>
  );
};

export default PageRepricing;
