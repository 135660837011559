import React from 'react';
import useResetDevice from '@/hooks/useResetDevice';
import { useSelector } from '@/hooks/useSelector';
import { useAsync } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Typography } from '@/components/ui/Typography';
import { UIButtonWithIcon } from '@/components/ui/Button';
import { CTAContainer } from '@/components/layout/CTAContainer';
import GetAppIcon from '@material-ui/icons/GetApp';

import * as S from './style';
import {
  DownloadCsvRequest,
  LoadStoresRestService,
  RecapReaderStoreUploadResponse,
} from '@/api/receive';
import { useHistory, useLocation } from 'react-router';
import { PageLoader } from '@/components/ui/PageLoader';
import { UIBox } from '@/components/ui/Box';
import useHandleProcess from '@/hooks/useHandleProcess';
import { AppRoutes } from '@/app/routers';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { useSignalRContext } from '@/context/signalR';
import { useAppDispatch } from '@/app/store';
import { setProcessLocked } from '@/features/loader/loaderSlice';

const PageReaderUploadRecap: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const handleProcess = useHandleProcess();
  const query = new URLSearchParams(location.search);
  const readerId = query.get('id');
  const { resetDevice } = useResetDevice();
  const { connect, unsubscribeStore } = useSignalRContext();
  const deviceInUse = useSelector(state => state.devices?.deviceInUse);
  const storeCode = useSelector(
    state => state.currentStore.store?.storeCode || ''
  );

  const [loading, setLoading] = React.useState<boolean>(false);
  const [errorFetchRecap, setErrorFetchRecap] = React.useState<boolean>(false);
  const [errorDownloadCSV, setErrorDownloadCSV] =
    React.useState<boolean>(false);
  const [recap, setRecap] = React.useState<RecapReaderStoreUploadResponse>();
  const [loadingReactiveStore, setLoadingReactiveStore] =
    React.useState<boolean>(false);
  const [errorReactiveStore, setErrorReactiveStore] =
    React.useState<boolean>(false);

  const fetchRecap = React.useCallback(async () => {
    if (readerId) {
      try {
        setLoading(true);

        const response =
          await LoadStoresRestService.loadstoresRecapReaderStoreUpload({
            requestBody: {
              storeCode,
              readerId,
            },
          });

        if (response) {
          setRecap(response);
        }

        setLoading(false);
      } catch {
        setLoading(false);
        setErrorFetchRecap(true);
      }
    }
  }, [readerId, storeCode]);

  const downloadCSV = async (
    type: DownloadCsvRequest['type']
  ): Promise<void> => {
    if (readerId) {
      try {
        const response = await LoadStoresRestService.loadstoresDownloadCsv({
          requestBody: {
            id: readerId,
            type,
          },
        });

        if (response) {
          const linkSource = `data:application/csv;base64,${response.base64}`;
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.download = response.fileName;
          downloadLink.click();
        }
      } catch {
        setErrorDownloadCSV(true);
      }
    }
  };

  useAsync(async () => {
    await fetchRecap();
  }, []);

  useAsync(async () => {
    if (deviceInUse) {
      await resetDevice();
    }
  }, []);

  const handleReactiveStore = async (): Promise<void> => {
    try {
      setLoadingReactiveStore(true);

      await handleProcess({ process: 'RSUP', isLocked: false });

      dispatch(
        setProcessLocked({
          process: 'readerProcessLocked',
          locked: false,
        })
      );

      await connect('RSUP');
      await unsubscribeStore();

      history.push(AppRoutes.INTRO);
    } catch (e) {
      setLoadingReactiveStore(false);
      setErrorReactiveStore(true);
    }
  };

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          {recap?.status === 'LOADING' ? (
            <>
              <UIBox
                mt={20}
                width="100%"
                height="100%"
                alignItems="center"
                justifyContent="center"
              >
                <S.Title>
                  <Typography font="heavy">
                    {t('pendingToBeUploaded')}
                  </Typography>
                </S.Title>
              </UIBox>
              <CTAContainer
                type="ONE_BUTTON"
                label={t('refresh')}
                onClick={(): Promise<void> => fetchRecap()}
              />
            </>
          ) : (
            <>
              <S.PageWrapper>
                <S.Title>
                  <Typography font="heavy">{t('readerStoreUpload')}</Typography>
                </S.Title>
                <S.TagsWrapper>
                  <S.Tags>
                    <Typography margin="0 16px 0" color="grey">
                      {t('uploadedTags')}
                    </Typography>
                    <Typography size="xl" font="heavy">
                      {recap?.uploadedTags || 0} / {recap?.readTags || 0}
                    </Typography>
                  </S.Tags>
                  <S.DownloadWrapper>
                    <UIButtonWithIcon
                      label={t('downloadUploadedTags')}
                      startIcon={<GetAppIcon />}
                      disabled={recap?.uploadedTags === 0}
                      onClick={(): Promise<void> =>
                        downloadCSV('UPLOADED_TAGS')
                      }
                    />
                  </S.DownloadWrapper>
                </S.TagsWrapper>
              </S.PageWrapper>
              <S.List mb="110px">
                <S.ListHeader>
                  <Typography font="heavy" size="lg">
                    {t('epcCode')}
                  </Typography>
                  <Typography font="heavy" size="lg">
                    {t('descriptionError')}
                  </Typography>
                </S.ListHeader>
                {recap &&
                  recap.epcs &&
                  recap.epcs.map(({ epcCode, descriptionError }) => (
                    <S.ListItem>
                      {epcCode && <Typography>{epcCode}</Typography>}
                      {descriptionError && (
                        <Typography>{descriptionError}</Typography>
                      )}
                    </S.ListItem>
                  ))}
              </S.List>
              <CTAContainer
                type="TWO_BUTTONS"
                backButtonLabel={t('reactiveStore')}
                mainButtonLabel={t('downloadExceptions')}
                onBackClick={handleReactiveStore}
                loading={loadingReactiveStore}
                disabledMainAction={recap?.epcs?.length! === 0}
                onClick={(): Promise<void> => downloadCSV('ERROR_TAGS')}
              />
              <ErrorSnackbar
                open={errorFetchRecap}
                setIsOpen={setErrorFetchRecap}
                errorMessage={t('error.fetchRecapReaderUpload')}
              />
              <ErrorSnackbar
                open={errorReactiveStore}
                setIsOpen={setErrorReactiveStore}
                errorMessage={t('error.reactiveStore')}
              />
              <ErrorSnackbar
                open={errorDownloadCSV}
                setIsOpen={setErrorDownloadCSV}
                errorMessage={t('error.downloadFile')}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default PageReaderUploadRecap;
