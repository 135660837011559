import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { FormControlLabel, List, ListSubheader } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import Checkbox from '@material-ui/core/Checkbox';
import { palette } from '@/theme';
import { RepricingListItem } from '@/components/layout/RepricingListItem';
import { PaginationList } from '@/components/layout/PaginationList';
import { UIBox } from '@/components/ui/Box';
import { RepriceBrand, RepriceUpc } from '@/api/receive';
import { Typography } from '@/components/ui/Typography';
import { useSelector } from '@/hooks/useSelector';
import { useTranslation } from 'react-i18next';

interface RepriceBrandListProps {
  brand: RepriceBrand;
  totalUpcs: number;
  checkedUpcs: string[];
  selectedTab: number;
  setCheckedUpcs: React.Dispatch<React.SetStateAction<string[]>>;
  setUpcPrintQuantity: React.Dispatch<
    React.SetStateAction<Map<string, number>>
  >;
  onSeeItemsClick: (brandName: string, upcs: RepriceUpc[]) => void;
}

//#region - Styled Components
const StyledCheckbox = styled(Checkbox)`
  svg {
    fill: #005192;
  }
`;

const StyledList = styled(List)`
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid ${palette.colors.lightGrey};
  background: ${palette.colors.midGrey};
  margin-bottom: 60px;
`;

const StyledSubHeader = styled(ListSubheader)`
  background: ${palette.colors.lightGrey};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  padding: 0 24px;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
`;

const StyledSubHeaderInfo = styled(UIBox)`
  align-items: center;

  &&& > span {
    cursor: pointer;
    color: #005192;
  }

  & > * {
    &:not(:last-child) {
      margin-right: 21px;
    }
  }
`;

const StyledWarningWrapper = styled(UIBox)`
  align-items: center;

  & > * {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`;

const StyledWarningIcon = styled(ErrorIcon)`
  fill: #f6bb3b;
  width: 20px;
  height: 20px;
`;
//#endregion

const RepriceBrandList: React.FC<RepriceBrandListProps> = ({
  brand,
  selectedTab,
  checkedUpcs,
  totalUpcs,
  setCheckedUpcs,
  setUpcPrintQuantity,
  onSeeItemsClick,
}) => {
  const { t } = useTranslation();
  const { brandName, upcs } = brand;

  const { printHasError, printIsLoading } = useSelector(state => state.reprice);

  const [upcsInPage, setUpcsInPage] = useState<RepriceUpc[]>([]);
  const [brandSelected, setBrandSelected] = useState<boolean>(false);

  useEffect(() => {
    if (!printHasError && !printIsLoading) {
      setBrandSelected(false);
    }
  }, [printHasError, printIsLoading, setCheckedUpcs]);

  const upcsInList = useMemo(
    () =>
      upcsInPage
        .filter(({ numberItems }) => numberItems !== 0)
        .map(({ upcCode }) => upcCode),
    [upcsInPage]
  );

  useEffect(() => {
    // Takes only the selectable upcs in common between upcInList & checkedUpcs
    const selectableUps = checkedUpcs.filter(upc => upcsInList.includes(upc));

    if (upcsInList.length !== 0 && selectableUps.length === upcsInList.length) {
      setBrandSelected(true);
    } else {
      setBrandSelected(false);
    }
  }, [checkedUpcs, upcsInList]);

  useEffect(() => {
    setBrandSelected(false);
  }, [selectedTab]);

  const handleSelectAll = (upcs: RepriceUpc[]): void => {
    const upcCodes = upcs
      .filter(({ numberItems }) => numberItems > 0)
      .map(({ upcCode }) => upcCode);

    setBrandSelected(brand => !brand);

    if (brandSelected) {
      setCheckedUpcs(prevChecked =>
        prevChecked.filter(item => !upcCodes.includes(item))
      );
    } else {
      setCheckedUpcs(prevChecked => [
        ...new Set([...prevChecked, ...upcCodes]),
      ]);
    }
  };

  const onProductCheckboxClick = (upc: string, brandName?: string): void => {
    if (checkedUpcs.includes(upc)) {
      setCheckedUpcs(prevChecked => prevChecked.filter(item => item !== upc));

      if (brandName) {
        if (brandSelected) {
          setBrandSelected(false);
        }
      }
    } else {
      setCheckedUpcs(prevChecked => [...prevChecked, upc]);
    }
  };

  return (
    <StyledList
      subheader={
        <StyledSubHeader>
          <FormControlLabel
            control={
              <StyledCheckbox
                checked={brandSelected}
                onChange={(): void => handleSelectAll(upcsInPage)}
              />
            }
            label={brandName}
          />
          {upcs.find(
            ({ firstPrice, secondPrice }) =>
              Number(firstPrice?.price) === 0 ||
              Number(secondPrice?.price) === 0
          ) && (
            <StyledSubHeaderInfo>
              <StyledWarningWrapper>
                <StyledWarningIcon />
                <Typography size="xs">
                  {t('reprice.items_with_price_zero', {
                    price: 0,
                  })}
                </Typography>
              </StyledWarningWrapper>
              <Typography
                font="heavy"
                onClick={(): void => onSeeItemsClick(brandName, upcs)}
              >
                {t('see_items')}
              </Typography>
            </StyledSubHeaderInfo>
          )}
        </StyledSubHeader>
      }
    >
      <PaginationList
        setItemsInPage={setUpcsInPage}
        data={upcs}
        pageSize={totalUpcs}
        renderItem={(repriceItem: RepriceUpc): JSX.Element => (
          <RepricingListItem
            $v2={repriceItem.statusPrint !== 'not printed'}
            key={`${repriceItem.upcCode}-${repriceItem.brandDescription}`}
            repriceItem={repriceItem}
            checkedUpcs={checkedUpcs}
            brandName={brandName}
            setUpcPrintQuantity={setUpcPrintQuantity}
            onProductCheckboxClick={onProductCheckboxClick}
            printStatusIsVisible={repriceItem.statusPrint !== 'not printed'}
            printCounterIsVisible={repriceItem.statusPrint !== 'not printed'}
          />
        )}
      />
    </StyledList>
  );
};

export default RepriceBrandList;
