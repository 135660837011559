import React from 'react';
import { ModalScanDetailsV2 } from '@/components/layout/ModalScanDetailsV2';
import { EnumMode } from '@/types/enum';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { useHistory } from 'react-router';
import { AppRoutes } from '@/app/routers';
import { useTranslation } from 'react-i18next';
import { Typography } from '@/components/ui/Typography';
import { UIButtonWithIcon } from '@/components/ui/Button';
import GetAppIcon from '@material-ui/icons/GetApp';

import * as S from './style';
import { ModalAttention } from '@/components/layout/ModalAttention';
import useFinishScan from '@/hooks/device/useFinishScan';
import { MessageBody, useSignalRContext } from '@/context/signalR';
import { useAsync } from 'react-use';
import { useSelector } from 'react-redux';
import { LoadStoresRestService } from '@/api/receive';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import * as XLSX from 'xlsx';
import useHandleProcess from '@/hooks/useHandleProcess';
import { useAppDispatch } from '@/app/store';
import { setProcessLocked } from '@/features/loader/loaderSlice';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ScanDevicesService } from '@/api';
import { tagsMQTTDevice } from '@/features/devices/devicesSlice';

export type Reader = {
  users: string[];
  owner: string;
  status: string;
  epcs: string[];
  id?: string;
};

const PageReaderUpload: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const finishScan = useFinishScan();
  const handleProcess = useHandleProcess();
  const [isStartScanning, setIsStartScanning] = React.useState<boolean>(false);
  const [reader, setReader] = React.useState<MessageBody<Reader>>();
  const [openScanModal, setScanModalVisibility] = React.useState<boolean>(true);
  const [confirmError, setConfirmError] = React.useState<boolean>(false);
  const [loadingConfirm, setLoadingConfirm] = React.useState<boolean>(false);
  const [confirmModalIsVisible, setConfirmModalVisibility] =
    React.useState<boolean>(false);

  const { deviceInUse } = useSelector(state => state.devices);
  const tags = useSelector(state => state.devices.tags);

  const userId = useSelector(state => state.user.username || '');
  const storeCode = useSelector(
    state => state.currentStore.store?.storeCode || ''
  );

  const {
    connection,
    connect,
    connected,
    subscribeStore,
    unsubscribeStore,
    send,
    error,
    errorMessage,
    setError,
    retrieveStatus,
  } = useSignalRContext();

  const isOperator = React.useMemo(() => {
    if (reader) {
      return userId.toLowerCase() !== reader?.data.owner.toLowerCase();
    }

    return false;
  }, [reader, userId]);

  useAsync(async () => {
    if (connection && !connected) {
      await connect('RSUP');
    }
  }, [connection]);

  useAsync(async () => {
    if (connected) {
      await subscribeStore();
      await subscribeStore();
    }
  }, [connection, connected]);

  useAsync(async () => {
    connection?.on('newMessage', (message: string) => {
      const data = JSON.parse(Buffer.from(message, 'base64').toString());

      // if (reader.storeCode !== storeCode) return;
      console.group('Signalr - Reader Data');
      console.log('Users connected', data.data.users);
      console.log('Epc codes', data.data.epcs);
      console.groupEnd();

      setReader(data);
    });
  }, [connection]);

  useAsync(async () => {
    connection?.on('newUser', (message: string) => {
      const data = JSON.parse(Buffer.from(message, 'base64').toString());

      console.group('Signalr - New user connected');
      console.log('Reader owner', data.data.owner);
      console.log('Current reader data', data.data.epcs);
      console.log('Connected users', data.data.users);
      console.groupEnd();

      setReader(data);
    });
  }, [connected]);

  useAsync(async () => {
    if (tags.length > 0) {
      await send<Reader>({
        ...reader!.data,
        epcs: tags,
      });
    }
  }, [send, tags]);

  React.useEffect(() => {
    if (isOperator) {
      if (
        reader?.data.status === 'TERMINATED' ||
        reader?.data.status === 'PENDING' ||
        reader?.data.status === 'COMPLETED'
      ) {
        history.push(AppRoutes.STORE_MIGRATOR);
      }
    }
  }, [history, isOperator, reader?.data.status]);

  const onDownloadTagsClick = async (): Promise<void> => {
    if (reader && reader.data) {
      const rows: { epc: string }[] = reader.data.epcs.map(epc => ({
        epc,
      }));

      const name = `${storeCode}_reader_tags`;
      const format = `${name}.csv`;

      const worksheet = XLSX.utils.json_to_sheet(rows);
      const workbook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(workbook, worksheet, name);
      XLSX.utils.sheet_add_aoa(worksheet, [['EPC']]);

      worksheet['!cols'] = [
        { wch: 30 },
        { hidden: true },
        { hidden: true },
        { hidden: true },
      ];

      XLSX.writeFile(workbook, format, {
        compression: true,
      });
    }
  };

  const onConfirmClick = async (): Promise<void> => {
    if (reader) {
      try {
        setLoadingConfirm(true);

        const response =
          await LoadStoresRestService.loadstoresConfirmReaderStoreUpload({
            requestBody: {
              storeCode,
              epcs: reader.data.epcs || [],
            },
          });

        if (response.id) {
          const createData = (data: Reader): Reader => ({
            ...data,
            id: response.id,
            status: 'PENDING',
          });

          setReader(prevState => {
            if (prevState) {
              return {
                ...prevState,
                data: createData(prevState.data),
              };
            }
          });

          await send<Reader>(createData(reader.data));

          setConfirmModalVisibility(false);
          setLoadingConfirm(false);

          history.push(
            `${AppRoutes.UPLOAD_STORE_READER_RECAP}?id=${response.id}`
          );
        }
      } catch {
        setLoadingConfirm(false);
        setConfirmError(true);
      }
    }
  };

  const onFinishScanClick = async (): Promise<void> => {
    const epcs = await finishScan.execute();

    if (reader) {
      await send<Reader>({
        ...reader.data,
        epcs,
      });
    }
  };

  const onScanClick = (): void => {
    setScanModalVisibility(true);
  };

  const onQuitClick = async (): Promise<void> => {
    await unsubscribeStore();

    if (!isOperator) {
      await handleProcess({ process: 'RSUP', isLocked: false });

      dispatch(
        setProcessLocked({
          process: 'readerProcessLocked',
          locked: false,
        })
      );
    }

    history.push(AppRoutes.STORE_MIGRATOR);
  };

  const onRefreshTags = async (): Promise<void> => {
    const { tags: deviceTags } = await ScanDevicesService.getTags({
      devId: deviceInUse?.deviceId!,
    });
    if (deviceTags && deviceTags.length > 0) {
      dispatch(tagsMQTTDevice(deviceTags));
    }
    retrieveStatus('RSUP');
  };

  return (
    <>
      <ModalScanDetailsV2
        open={openScanModal}
        onClose={(): void => setScanModalVisibility(false)}
        defaultMode={EnumMode.INVENTORY}
        disabledModes={[EnumMode.FIND]}
        hideModes={[EnumMode.FIND]}
        isScanningAgain={finishScan.isCompleted}
        setIsScanCompleted={finishScan.setIsScanCompleted}
        onStartReader={(): void => {
          setIsStartScanning(true);
          setScanModalVisibility(false);
        }}
      />
      <ModalAttention
        messageMaxWidth="80%"
        open={confirmModalIsVisible}
        message={t('checkEveryoneHasFinishedScan')}
        loadingConfirm={loadingConfirm}
        disableConfirm={loadingConfirm}
        onConfirmClick={onConfirmClick}
        onClose={(): void => setConfirmModalVisibility(false)}
      />
      <S.PageWrapper>
        <S.Title>
          <Typography font="heavy">{t('readerStoreUpload')}</Typography>
        </S.Title>
        <S.Tags>
          <Typography margin="0 0 32px" color="grey">
            {t('totalTagsRead')}
          </Typography>
          <Typography className="tags-read" font="heavy">
            {reader?.data?.epcs?.length || 0}
          </Typography>
        </S.Tags>

        <UIButtonWithIcon
          margin="32px 0"
          label={t('refresh')}
          startIcon={<RefreshIcon />}
          onClick={onRefreshTags}
        />

        {userId === reader?.data.owner && (
          <S.DownloadWrapper>
            <UIButtonWithIcon
              label={t('downloadTagsRead')}
              startIcon={<GetAppIcon />}
              onClick={onDownloadTagsClick}
            />
          </S.DownloadWrapper>
        )}
      </S.PageWrapper>
      {!finishScan.isCompleted && isStartScanning ? (
        <CTAContainer
          type="FINISH SCAN"
          onClick={onFinishScanClick}
          disabled={finishScan.isLoading}
          loading={finishScan.isLoading}
        />
      ) : (
        <>
          {isOperator ? (
            <CTAContainer
              type="TWO_BUTTONS"
              disabled={false}
              onBackClick={onQuitClick}
              backButtonLabel={t('quit')}
              mainButtonLabel={t('scan')}
              onClick={(): void => setScanModalVisibility(true)}
            />
          ) : (
            <CTAContainer
              type="DEFAULT"
              disabled={false}
              disabledConfirm={reader?.data.epcs.length === 0}
              onQuitClick={onQuitClick}
              onConfirmClick={(): void => setConfirmModalVisibility(true)}
              onScanClick={onScanClick}
              loadingConfirm={false}
            />
          )}
        </>
      )}
      <ErrorSnackbar
        open={error}
        setIsOpen={setError}
        errorMessage={errorMessage}
      />
      <ErrorSnackbar
        open={confirmError}
        setIsOpen={setConfirmError}
        errorMessage={t('error.readerConfirm')}
      />
    </>
  );
};

export default PageReaderUpload;
