import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import { useAppDispatch } from '@/app/store';
import { useSelector } from '@/hooks/useSelector';

import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { RecallBrandList } from '@/components/layout/RecallList/RecallBrandList';
import { RecallNotFoundList } from '@/components/layout/RecallList/RecallNotFoundList';
import { RecallErrorList } from '@/components/layout/RecallList/RecallErrorList';
import { ModalScanDetailsV2 } from '@/components/layout/ModalScanDetailsV2';
import { ModalDataSavedError } from '@/components/layout/ModalDataSaved';
import RefreshIcon from '@material-ui/icons/Refresh';

import { CTAContainer } from '@/components/layout/CTAContainer';
import {
  recallStatus,
  updateScannedQuantity,
} from '@/features/recall/recallSlice';
import { deleteTags, tagsMQTTDevice } from '@/features/devices/devicesSlice';

import {
  confirmRecallOrderEncoded,
  fetchRecallOrdersDetails,
} from '@/features/recall/recallSlice';
import { useRecallContext } from '@/context/recallContext';
import { PageLoader } from '@/components/ui/PageLoader';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';

import { EnumMode } from '@/types/enum';
import type { CustomRecallUpc } from '@/types/recallSlice';
import type { RecallConfirmUpcs } from '@/api/receive';
import { AppRoutes } from '@/app/routers';
import { ScanDevicesService } from '@/api';
import { UIButtonWithIcon } from '@/components/ui/Button';

const StyledOrder = styled(UIBox)`
  margin: 32px 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export type RecallItems = {
  found: CustomRecallUpc[];
  error: CustomRecallUpc[];
  missing: CustomRecallUpc[];
};

const PageRecallOrder: React.FC = () => {
  const { t } = useTranslation();
  const { idRecall } = useParams<{ idRecall: string }>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { recallItems, findItemsThroughTags, setRecallConfirmed } =
    useRecallContext();
  const [scanModalIsVisible, setScanModalVisibility] = useState<boolean>(false);
  const [isGettingTags, setGettingTags] = useState<boolean>(false);
  const [show3Buttons, setShow3Buttons] = useState<boolean>(false);
  const [scanCompleted, setScanCompleted] = useState<boolean>(false);
  const [isLoadingFinishScan, setLoadingFinishScan] = useState<boolean>(false);
  const [isMount, setIsMount] = useState<boolean>(true);
  const [errorConfirm, setErrorConfirm] = useState<boolean>(false);
  const [brandsLoadingError, setBrandsLoadingError] = useState<boolean>(false);
  const [isFinishScan, setFinishScan] = useState<boolean>(false);
  const [deviceError, setDeviceError] = useState<boolean>(false);
  const [deviceErrorMsg, setDeviceErrorMsg] = useState<string>();
  const [isConfirmModalErrorVisible, setConfirmModalErrorVisibility] =
    useState<boolean>(false);

  const {
    brands,
    fetchBrandsIsLoading,
    fetchBrandsHasError,
    confirmRecallOrderHasError,
    confirmRecallOrderIsLoading,
  } = useSelector(state => state.recall);

  const { deviceInUse, tags, deleteTagsHasError } = useSelector(
    state => state.devices
  );

  useAsync(async () => {
    try {
      await dispatch(fetchRecallOrdersDetails(idRecall)).unwrap();
    } catch {
      return;
    }
  }, []);

  useEffect(() => {
    if (!isMount) {
      findItemsThroughTags();
    }
  }, [findItemsThroughTags, isMount]);

  useEffect(() => {
    if (tags.length > 0) {
      dispatch(updateScannedQuantity(tags));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags.length]);

  useEffect(() => {
    if (isGettingTags) {
      setScanModalVisibility(false);
      setFinishScan(true);
    }
  }, [isGettingTags]);

  useEffect(() => {
    if (!scanCompleted) {
      setShow3Buttons(false);
    }
  }, [scanCompleted]);

  useEffect(() => {
    if (fetchBrandsHasError) {
      setBrandsLoadingError(true);
    }
  }, [fetchBrandsHasError]);

  useEffect(() => {
    if (confirmRecallOrderHasError) {
      setErrorConfirm(true);
    }
  }, [confirmRecallOrderHasError]);

  useEffect(() => {
    if (deleteTagsHasError) {
      setDeviceError(true);
      setDeviceErrorMsg(deleteTagsHasError.body.message);
    }
  }, [deleteTagsHasError]);

  const onUpdateClick = async (): Promise<void> => {
    const { tags: deviceTags } = await ScanDevicesService.getTags({
      devId: deviceInUse?.deviceId!,
    });
    if (deviceTags && deviceTags.length > 0) {
      dispatch(tagsMQTTDevice(deviceTags));
    }
  };

  const startScanClickHandler = (): void => setScanModalVisibility(true);

  const finishScanClickHandler = async (): Promise<void> => {
    try {
      setIsMount(false);
      setGettingTags(false);
      setScanCompleted(true);
      setLoadingFinishScan(true);

      const { tags: deviceTags, mode } = await ScanDevicesService.getTags({
        devId: deviceInUse?.deviceId!,
      });

      if (mode === 'no mode') {
        setLoadingFinishScan(false);
        setDeviceErrorMsg(t('error.deviceCommunication'));
        return setDeviceError(true);
      }

      if (deviceTags && deviceTags.length > 0) {
        setIsMount(false);
        dispatch(tagsMQTTDevice(deviceTags));
      }

      setTimeout(async () => {
        const { code, message } = await ScanDevicesService.activateScanning({
          requestBody: {
            dev_id: deviceInUse?.deviceId!,
            enable: 'False',
          },
        });

        if (code !== 'OK') {
          setDeviceErrorMsg(message);
          setLoadingFinishScan(false);
          return setDeviceError(true);
        }
      }, 1000);

      setTimeout(async () => {
        await dispatch(deleteTags());
        setLoadingFinishScan(false);
        setShow3Buttons(true);
      }, 2000);
    } catch (e) {
      setLoadingFinishScan(false);
      setDeviceError(true);
    }
  };

  const scanAgainClickHandler = (): void => {
    setScanModalVisibility(true);
  };

  const modalSavedErrorCloseHandler = (): void => {
    setConfirmModalErrorVisibility(false);

    history.push(AppRoutes.PDF_DOWNLOAD_RECALL, {
      isRecallConfirmFailed: true,
    });
  };

  const closeModalScanHandler = (): void => {
    setScanModalVisibility(false);
  };

  const confirmClickHandler = async (): Promise<void> => {
    try {
      const createRequestUpc = (key: keyof RecallItems): RecallConfirmUpcs[] =>
        recallItems[key].map(({ upcCode, found }) => ({
          upcCode,
          recallQuantity: Array.isArray(found) ? found.length : 0,
          epcs: found || [],
        }));

      const found = createRequestUpc('found');
      const missing = createRequestUpc('missing');
      const upcs = [...found, ...missing].filter(({ epcs }) => epcs.length > 0);

      const requestBody = { idRecall, upcs };
      await dispatch(confirmRecallOrderEncoded(requestBody)).unwrap();
      dispatch(recallStatus('Completed'));
      setRecallConfirmed(true);
      history.push(AppRoutes.PDF_DOWNLOAD_RECALL);
    } catch {
      dispatch(recallStatus('Failed'));
      setConfirmModalErrorVisibility(true);
    }
  };

  const quitClickHandler = (): void => {
    history.push(AppRoutes.INTRO);
  };

  const totalRecallItems = brands
    .flatMap(({ upcs }) => upcs)
    .map(({ recallQuantity }) => recallQuantity)
    .reduce((a, b) => a + b, 0);

  const foundEpcs = recallItems.found.flatMap(({ found }) => found).length;
  const notFoundEpcs = recallItems.missing
    .map(({ found, recallQuantity }) => recallQuantity - found?.length!)
    .reduce((prev, curr) => prev + curr, 0);

  if (fetchBrandsIsLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <ModalScanDetailsV2
        resetDeviceInUse={!scanCompleted}
        open={scanModalIsVisible}
        startGetTags={isGettingTags}
        startGettingTags={setGettingTags}
        isScanningAgain={scanCompleted}
        setIsScanCompleted={setScanCompleted}
        onClose={closeModalScanHandler}
        defaultMode={EnumMode.FIND}
        disabledModes={[EnumMode.INVENTORY]}
        hideModes={[EnumMode.INVENTORY]}
        nextPageURL={`${AppRoutes.RECALL}/${idRecall}`}
        onStartReader={(): void => setGettingTags(true)}
      />
      <ModalDataSavedError
        $minWidth="400px"
        $minHeight="160px"
        iconType="ERROR"
        open={isConfirmModalErrorVisible}
        onClose={modalSavedErrorCloseHandler}
        title={t('modal.processInterrupted')}
        message={t('modal.datanotsaved')}
      />
      <StyledOrder>
        <div></div>
        <Typography font="heavy">{t('recallOrder', { idRecall })}</Typography>
        <UIButtonWithIcon
          label={t('refresh')}
          startIcon={<RefreshIcon />}
          onClick={onUpdateClick}
        />
      </StyledOrder>
      <UIBox width="100%" flexDirection="column" marginBottom={15}>
        <UIBox pt={3} pl={3}>
          <Typography font="heavy">
            {t('itemsForRecall', { items: totalRecallItems })}
          </Typography>
        </UIBox>
        {recallItems.error.length > 0 && (
          <UIBox width="100%" flexDirection="column">
            <UIBox pt={3} pl={3}>
              <Typography>
                {t('quantityErrorItems', { items: recallItems.error.length })}
              </Typography>
            </UIBox>
            <RecallErrorList
              upcs={recallItems.error}
              isGettingTags={isGettingTags}
            />
          </UIBox>
        )}
        {recallItems.missing.length > 0 && (
          <UIBox width="100%" flexDirection="column">
            <UIBox pt={3} pl={3}>
              <Typography>
                {t('notFoundItems', { items: notFoundEpcs })}
              </Typography>
            </UIBox>
            <RecallNotFoundList upcs={recallItems.missing} />
          </UIBox>
        )}
        {recallItems.found.length > 0 && (
          <UIBox width="100%" flexDirection="column">
            <UIBox pt={3} pl={3}>
              <Typography>{t('foundItems', { items: foundEpcs })}</Typography>
            </UIBox>
            {[
              ...new Set(
                recallItems.found.map(
                  ({ brandDescription }) => brandDescription
                )
              ),
            ].map((brandDescription, index) => (
              <RecallBrandList
                isMount={isMount}
                key={`${brandDescription}-${index}`}
                brandName={brandDescription!}
                upcs={recallItems.found.filter(
                  brand => brand.brandDescription === brandDescription
                )}
              />
            ))}
          </UIBox>
        )}
        {isMount && (
          <UIBox width="100%" flexDirection="column">
            {brands.map((brand, index) => (
              <RecallBrandList
                key={`${brand.brandName}-${index}`}
                isMount={isMount}
                {...brand}
              />
            ))}
          </UIBox>
        )}
      </UIBox>
      {!show3Buttons ? (
        <CTAContainer
          type="ONE_BUTTON"
          disabled={isFinishScan && tags.length === 0}
          onClick={(): void | Promise<void> =>
            !isFinishScan ? startScanClickHandler() : finishScanClickHandler()
          }
          loading={isLoadingFinishScan}
          label={
            !isFinishScan
              ? t('page.recallOrder.btn.scanNow')
              : t('page.recallOrder.btn.finishScan')
          }
        />
      ) : (
        <CTAContainer
          type="THREE_BUTTONS"
          disabledConfirm={
            recallItems.error.length > 0 ||
            (recallItems.missing.length === 0 && recallItems.found.length === 0)
          }
          loadingConfirm={confirmRecallOrderIsLoading}
          onConfirmClick={confirmClickHandler}
          onQuitClick={quitClickHandler}
          onScanClick={scanAgainClickHandler}
          disableSecondaryButton={recallItems.missing.length === 0}
          mainButtonLabel={t('confirm')}
          secondaryButtonLabel={t('scanagain')}
          backButtonLabel={t('quit')}
        />
      )}

      <ErrorSnackbar
        open={errorConfirm}
        setIsOpen={setErrorConfirm}
        errorMessage={
          confirmRecallOrderHasError?.body?.errorMessage ||
          confirmRecallOrderHasError?.body
        }
      />

      <ErrorSnackbar
        open={brandsLoadingError}
        setIsOpen={setBrandsLoadingError}
        errorMessage={fetchBrandsHasError?.body}
      />
      <ErrorSnackbar
        open={deviceError}
        setIsOpen={setDeviceError}
        errorMessage={deviceErrorMsg}
      />
    </>
  );
};

export default PageRecallOrder;
