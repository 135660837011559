import React, { FC, useState } from 'react';
import { Chip } from '@material-ui/core';
import { UIBox } from '@/components/ui/Box';
import styled from 'styled-components';
import { useToggle } from 'react-use';
import { ProductImage } from '@/components/layout/ProductImage';
import { ProductAccordion } from '@/components/layout/ProductAccordion';
import { ProductAccordionItem } from '@/components/layout/ProdocutAccordionItem';
import { ProductPrice } from '@/components/layout/ProductPrice';
import { Typography } from '@/components/ui/Typography';
import { colors } from '@/theme/';
import { ReprintCounter } from '../ReprintCounter';
import { useTranslation } from 'react-i18next';

import { RepriceUpc } from '@/api/receive';
import { ModalProductDetails } from '../ModalProductDetails';

export type CounterActionType = 'increment' | 'decrement';

interface RepricingListItemProps {
  $v2?: boolean;
  checkboxIsVisible?: boolean;
  printCounterIsVisible?: boolean;
  itemsInStockAreVisible?: boolean;
  printStatusIsVisible?: boolean;
  checkedUpcs?: string[];
  brandName: string;
  repriceItem: RepriceUpc;
  setUpcPrintQuantity: React.Dispatch<
    React.SetStateAction<Map<string, number>>
  >;
  onProductCheckboxClick?: (upc: string, brandName?: string) => void;
}

//#region - Styled Components
const StyledProductItemWrapper = styled(UIBox)`
  flex-direction: column;
  padding: 20px 18px;
  width: 100%;
  background: #fbfbfb;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${colors.lightGrey};
  }
`;

const StyledProductGrid = styled(UIBox)`
  display: grid;
  grid-template-columns: max-content auto max-content max-content;
  align-items: center;
  gap: 16px;
`;

const StyledUserActions = styled(UIBox)`
  align-items: center;
`;

const StyledContentWrapper = styled(UIBox)`
  flex-direction: column;

  && > * {
    line-height: 19px;
  }
`;

const StyledItemsInStockWrapper = styled(UIBox)`
  padding-right: 18px;
  padding-bottom: 8px;
  align-self: flex-end;
  justify-self: flex-end;
`;

const StyledPriceWrapper = styled(UIBox)`
  flex-direction: column;
  align-items: flex-end;
  justify-self: flex-end;
`;

const StyledPrice = styled(UIBox)`
  & > div {
    margin-left: 8px;
  }
`;

const StyledAccortionItemContent = styled(UIBox)`
  align-items: center;
`;

const StyledPrintStatusChip = styled(Chip)<{
  status: RepriceUpc['statusPrint'];
}>`
  text-transform: uppercase;
  height: 19px;
  margin-bottom: 8px;
  background: ${({ status }): string =>
    status === 'done' ? `${colors.green}` : `${colors.red}`};
  color: ${colors.white};
`;
//#endregion

const RepricingListItem: FC<RepricingListItemProps> = ({
  $v2 = false,
  checkboxIsVisible = true,
  printCounterIsVisible = true,
  itemsInStockAreVisible = true,
  printStatusIsVisible = true,
  repriceItem,
  checkedUpcs,
  brandName,
  setUpcPrintQuantity,
  onProductCheckboxClick,
}) => {
  const {
    brandDescription,
    modelCode,
    colorCode,
    commodity,
    styleName,
    upcCode,
    firstPrice,
    secondPrice,
    numberItems,
    statusPrint,
    imageUrl,
  } = repriceItem;

  const { t } = useTranslation();
  const [counter, setCounter] = useState<number>(1);
  const [$isAccordionExpanded, toggleAccordion] = useToggle(false);
  const [isProductModalVisible, setProductModalVisibility] =
    useState<boolean>(false);

  const onIncrementClick = (): void => {
    setCounter(counter + 1);
  };

  const onDecrementClick = (): void => {
    setCounter(counter - 1);
  };

  React.useEffect(() => {
    setUpcPrintQuantity(prev => {
      const prevValue = prev.get(upcCode);
      if (prevValue) {
        return prev.set(upcCode, counter);
      } else {
        return prev.set(upcCode, 1);
      }
    });
  }, [setUpcPrintQuantity, upcCode, counter]);

  return (
    <>
      <ModalProductDetails
        productCode={upcCode}
        open={isProductModalVisible}
        onClose={(): void => setProductModalVisibility(false)}
      />
      <StyledProductItemWrapper>
        <StyledProductGrid>
          <StyledUserActions>
            <ProductImage
              $v2={$v2}
              imageUrl={imageUrl}
              checkboxIsVisible={checkboxIsVisible}
              checkboxIsDisabled={numberItems === 0}
              upc={upcCode}
              checkedUpcs={checkedUpcs}
              brandName={brandName}
              onClick={(): void => setProductModalVisibility(true)}
              onCheckboxClick={(): void =>
                onProductCheckboxClick?.(upcCode, brandName)
              }
              $isAccordionExpanded={$isAccordionExpanded}
              onClickAccordion={toggleAccordion}
            />
          </StyledUserActions>
          <StyledContentWrapper>
            <Typography font="heavy" margin="0 0 4px">
              {brandDescription} - {commodity} {colorCode} {styleName}
            </Typography>
            <Typography font="light">
              {upcCode} - {modelCode} - {commodity}
            </Typography>
          </StyledContentWrapper>
          {itemsInStockAreVisible && (
            <StyledItemsInStockWrapper>
              <Typography size="xs" color="grey">
                {t('items_in_stock', { items: numberItems })}
              </Typography>
            </StyledItemsInStockWrapper>
          )}
          <StyledPriceWrapper>
            {printStatusIsVisible && (
              <StyledPrintStatusChip label={statusPrint} status={statusPrint} />
            )}
            {firstPrice?.price && (
              <StyledPrice>
                {t(firstPrice.type || '')}
                <ProductPrice
                  currency={firstPrice.currency}
                  price={firstPrice.price}
                />
              </StyledPrice>
            )}
            {secondPrice?.price && (
              <StyledPrice>
                {t(secondPrice.type || '')}
                <ProductPrice
                  currency={secondPrice.currency}
                  price={secondPrice.price}
                />
              </StyledPrice>
            )}
          </StyledPriceWrapper>
        </StyledProductGrid>
        {printCounterIsVisible && (
          <ProductAccordion
            items="20"
            $v2={$v2}
            expanded={$isAccordionExpanded}
            toggleAccordion={toggleAccordion}
          >
            <ProductAccordionItem epcCode="">
              <StyledAccortionItemContent>
                <Typography font="heavy" size="sm">
                  {t('reprice.select_quantity_reprint')}
                </Typography>
                <ReprintCounter
                  counter={counter}
                  onIncrementClick={onIncrementClick}
                  onDecrementClick={onDecrementClick}
                  margin="0 0 0 46px"
                />
              </StyledAccortionItemContent>
            </ProductAccordionItem>
          </ProductAccordion>
        )}
      </StyledProductItemWrapper>
    </>
  );
};

export default RepricingListItem;
