import React, { FC, memo } from 'react';
import { UIBox } from '@/components/ui/Box';
import { UIButton } from '@/components/ui/Button';
import styled from 'styled-components';
import { theme } from '@/theme';
import { useTranslation } from 'react-i18next';
import { Typography } from '@/components/ui/Typography';

export interface SingleButtonProps {
  type:
    | 'ONE_BUTTON'
    | 'SCAN NOW'
    | 'SELECT'
    | 'GOT IT'
    | 'FINISH SCAN'
    | 'NEW CYCLE COUNT'
    | 'BACK';
  disabled?: boolean;
  label?: string;
  onClick: React.MouseEventHandler;
  loading?: boolean;
}

export interface DoubleButtonProps {
  type:
    | 'APPLY'
    | 'CYCLECOUNT SCAN NOW'
    | 'NEXT'
    | 'PRINT'
    | 'ADD USER'
    | 'SAVE'
    | 'TWO_BUTTONS';
  disabled?: boolean;
  disabledMainAction?: boolean;
  mainButtonCentered?: boolean;
  onClick: React.MouseEventHandler;
  onBackClick: React.MouseEventHandler;
  loading?: boolean;
  label?: JSX.Element | string;
  backButtonLabel?: string;
  mainButtonLabel?: string;
}

export interface MultipleButtonsProps {
  type: 'DEFAULT' | 'UNDO' | 'THREE_BUTTONS' | 'THREE_BUTTONS_RECEIVING';
  disabled?: boolean;
  onQuitClick: () => void;
  onConfirmClick: () => void;
  disabledConfirm?: boolean;
  loadingConfirm?: boolean;
  onScanClick?: () => void;
  onUndoClick?: () => void;
  undoDisabled?: boolean;
  hideBack?: boolean;
  disableSecondaryButton?: boolean;
  secondaryButtonOutlined?: boolean;
  mainButtonLabel?: string;
  secondaryButtonLabel?: string;
  backButtonLabel?: string;
}

export interface FourButtonsProps {
  type: 'FOUR_BUTTONS';
  disabled?: boolean;
  onFirstClick: () => void;
  onSecondClick?: () => void;
  onThirdClick?: () => void;
  onFourthClick: () => void;
  disabledFirstButton?: boolean;
  disabledSecondButton?: boolean;
  disabledThirdButton?: boolean;
  disabledFourthButton?: boolean;
  loadingFirstButton?: boolean;
  loadingSecondButton?: boolean;
  loadingThirdButton?: boolean;
  loadingFourthButton?: boolean;
  firstButtonLabel?: string;
  secondButtonLabel?: string;
  thirdButtonLabel?: string;
  fourthButtonLabel?: string;
}

export type ButtonType =
  | SingleButtonProps
  | DoubleButtonProps
  | MultipleButtonsProps
  | FourButtonsProps;

const StyledCTAContainer = styled(UIBox)`
  && {
    z-index: 10;
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 24px;
    background: ${theme.palette.common.white};
    box-shadow: 0 -1px 21px rgba(0, 0, 0, 0.25);
    justify-content: center;

    ${({ theme }): string =>
      `${theme.breakpoints.down('sm')} {
            align-items: center;
        }`}

    button {
      width: fit-content;
      padding: 12px 48px;

      &:not(:last-child) {
        margin-right: 24px;

        ${({ theme }): string =>
          `${theme.breakpoints.down('xs')} {
                  margin-right: 12px;
              }`}
      }
    }
  }

  @media print {
    &&& {
      display: none;
    }
  }
`;

const StyledInnerContainer = styled(UIBox)`
  margin-left: auto;
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;

  ${({ theme }): string =>
    `${theme.breakpoints.down('sm')} {
            margin-left: 0;
            margin-top: 24px;
            align-items: center;
            justify-content: center;
        }`}
`;

export const CTAContainer: FC<ButtonType> = memo((button): JSX.Element => {
  const { t } = useTranslation();

  switch (button.type) {
    case 'SCAN NOW':
    case 'GOT IT':
    case 'SELECT':
    case 'FINISH SCAN':
    case 'NEW CYCLE COUNT':
      return (
        <StyledCTAContainer>
          <UIButton
            onClick={button.onClick}
            label={t(button.type.replaceAll(' ', '-').toLocaleLowerCase())}
            outlined={false}
            disabled={button.loading || button.disabled}
            loading={button.loading}
          />
        </StyledCTAContainer>
      );

    case 'APPLY':
      return (
        <StyledCTAContainer>
          <UIButton
            onClick={button.onBackClick}
            label={t('back')}
            outlined={true}
            disabled={button.loading || button.disabled}
            loading={button.loading}
          />
          <StyledInnerContainer>
            <UIButton
              onClick={button.onClick}
              label={t('apply')}
              outlined={false}
              disabled={button.loading || button.disabledMainAction}
              loading={button.loading}
            />
          </StyledInnerContainer>
        </StyledCTAContainer>
      );

    case 'NEXT':
      return (
        <StyledCTAContainer>
          <UIButton
            onClick={button.onBackClick}
            label={t('back')}
            outlined={true}
            disabled={button.loading || button.disabled}
            loading={button.loading}
          />
          <StyledInnerContainer>
            <UIButton
              onClick={button.onClick}
              label={t('next')}
              outlined={false}
              disabled={button.loading || button.disabledMainAction}
              loading={button.loading}
            />
          </StyledInnerContainer>
        </StyledCTAContainer>
      );

    case 'THREE_BUTTONS_RECEIVING':
      return (
        <StyledCTAContainer>
          {!button.hideBack && (
            <UIButton
              onClick={button.onQuitClick}
              label={button.backButtonLabel || ''}
              outlined={true}
              disabled={button.disabled}
            />
          )}
          <UIButton
            onClick={button.onScanClick}
            label={button.secondaryButtonLabel || ''}
            outlined={button.secondaryButtonOutlined || false}
            disabled={button.disableSecondaryButton || button.disabled}
          />
          <StyledInnerContainer>
            {button.disabledConfirm && (
              <Typography size="sm" color="grey">
                {t('receiving.disableConfirm')}
              </Typography>
            )}
            <UIButton
              onClick={button.onConfirmClick}
              label={button.mainButtonLabel || ''}
              outlined={false}
              disabled={button.disabledConfirm || button.loadingConfirm}
              loading={button.loadingConfirm}
            />
          </StyledInnerContainer>
        </StyledCTAContainer>
      );

    case 'THREE_BUTTONS':
      return (
        <StyledCTAContainer>
          {!button.hideBack && (
            <UIButton
              onClick={button.onQuitClick}
              label={button.backButtonLabel || ''}
              outlined={true}
              disabled={button.disabled}
            />
          )}
          <StyledInnerContainer>
            <UIButton
              onClick={button.onScanClick}
              label={button.secondaryButtonLabel || ''}
              outlined={button.secondaryButtonOutlined || false}
              disabled={button.disableSecondaryButton || button.disabled}
            />
            <UIButton
              onClick={button.onConfirmClick}
              label={button.mainButtonLabel || ''}
              outlined={false}
              disabled={button.disabledConfirm || button.loadingConfirm}
              loading={button.loadingConfirm}
            />
          </StyledInnerContainer>
        </StyledCTAContainer>
      );

    case 'FOUR_BUTTONS':
      return (
        <StyledCTAContainer>
          <UIButton
            onClick={button.onFourthClick}
            label={button.fourthButtonLabel || ''}
            outlined
            disabled={button.disabledFourthButton}
          />
          <UIButton
            onClick={button.onThirdClick}
            label={button.thirdButtonLabel || ''}
            outlined
            disabled={button.disabledThirdButton}
          />
          <StyledInnerContainer>
            <UIButton
              onClick={button.onSecondClick}
              label={button.secondButtonLabel || ''}
              outlined
              disabled={button.disabledSecondButton || button.disabled}
            />
            <UIButton
              onClick={button.onFirstClick}
              label={button.firstButtonLabel || ''}
              outlined={false}
              disabled={button.disabledFirstButton || button.loadingFirstButton}
              loading={button.loadingFirstButton}
            />
          </StyledInnerContainer>
        </StyledCTAContainer>
      );

    case 'TWO_BUTTONS':
      return (
        <StyledCTAContainer>
          <UIButton
            onClick={button.onBackClick}
            label={button.backButtonLabel || t('back')}
            outlined={true}
            disabled={button.loading || button.disabled}
          />
          <StyledInnerContainer>
            <UIButton
              onClick={button.onClick}
              label={button.mainButtonLabel || t('save')}
              outlined={false}
              disabled={button.loading || button.disabledMainAction}
              loading={button.loading}
            />
          </StyledInnerContainer>
        </StyledCTAContainer>
      );

    case 'ONE_BUTTON':
      return (
        <StyledCTAContainer>
          <UIButton
            onClick={button.onClick}
            label={button.label || ''}
            outlined={false}
            disabled={button.disabled || button.loading}
            loading={button.loading}
          />
        </StyledCTAContainer>
      );

    case 'PRINT':
      return (
        <StyledCTAContainer>
          <UIButton
            onClick={button.onBackClick}
            label={t('back')}
            outlined={true}
            disabled={button.loading || button.disabled}
            loading={button.loading}
          />
          <StyledInnerContainer>
            {button.label}
            <UIButton
              onClick={button.onClick}
              label={t('print')}
              outlined={false}
              disabled={button.loading || button.disabledMainAction}
              loading={button.loading}
            />
          </StyledInnerContainer>
        </StyledCTAContainer>
      );

    case 'ADD USER':
      return (
        <StyledCTAContainer>
          <UIButton
            onClick={button.onBackClick}
            label={t('back')}
            outlined={true}
            disabled={button.loading || button.disabled}
            loading={button.loading}
          />
          <StyledInnerContainer>
            <UIButton
              onClick={button.onClick}
              label={t('addUser')}
              outlined={false}
              disabled={button.loading || button.disabledMainAction}
              loading={button.loading}
            />
          </StyledInnerContainer>
        </StyledCTAContainer>
      );

    case 'SAVE':
      return (
        <StyledCTAContainer>
          <UIButton
            onClick={button.onBackClick}
            label={t('back')}
            outlined={true}
            disabled={button.loading || button.disabled}
            loading={button.loading}
          />
          <StyledInnerContainer>
            <UIButton
              onClick={button.onClick}
              label={button.mainButtonLabel || t('save')}
              outlined={false}
              disabled={button.loading || button.disabledMainAction}
              loading={button.loading}
            />
          </StyledInnerContainer>
        </StyledCTAContainer>
      );

    case 'BACK':
      return (
        <StyledCTAContainer>
          <UIButton
            onClick={button.onClick}
            label={t('back')}
            outlined={true}
            disabled={button.loading || button.disabled}
            loading={button.loading}
          />
        </StyledCTAContainer>
      );

    case 'CYCLECOUNT SCAN NOW':
      return (
        <StyledCTAContainer>
          <UIButton
            onClick={button.onBackClick}
            label={t('back')}
            outlined={true}
            disabled={button.loading || button.disabled}
            loading={button.loading}
          />
          <StyledInnerContainer>
            <UIButton
              onClick={button.onClick}
              label={t('scan-now')}
              outlined={false}
              disabled={button.loading || button.disabledMainAction}
              loading={button.loading}
            />
          </StyledInnerContainer>
        </StyledCTAContainer>
      );

    case 'UNDO':
      return (
        <StyledCTAContainer>
          <UIButton
            onClick={button.onQuitClick}
            label={t('back')}
            outlined={true}
            disabled={button.disabled}
          />
          <StyledInnerContainer>
            <UIButton
              onClick={button.onUndoClick}
              label={t('undo')}
              outlined={true}
              disabled={button.undoDisabled}
            />
            <UIButton
              onClick={button.onConfirmClick}
              label={t('save')}
              outlined={false}
              disabled={button.disabledConfirm || button.loadingConfirm}
              loading={button.loadingConfirm}
            />
          </StyledInnerContainer>
        </StyledCTAContainer>
      );

    default:
      return (
        <StyledCTAContainer>
          <UIButton
            onClick={button.onQuitClick}
            label={t('quit')}
            outlined={true}
            disabled={button.disabled}
          />
          <StyledInnerContainer>
            <UIButton
              onClick={button.onScanClick}
              label={t('scanagain')}
              outlined={false}
              disabled={button.disabled}
            />
            <UIButton
              onClick={button.onConfirmClick}
              label={t('confirm')}
              outlined={false}
              disabled={button.disabledConfirm || button.loadingConfirm}
              loading={button.loadingConfirm}
            />
          </StyledInnerContainer>
        </StyledCTAContainer>
      );
  }
});

CTAContainer.displayName = 'CTAContainer';

export default CTAContainer;
