import React, { ReactElement } from 'react';
import {
  Switch,
  Route,
  Redirect,
  RouteProps,
  useHistory,
} from 'react-router-dom';

import PageLogin from '@/pages/PageLogin';
import PageLogout from '@/pages/PageLogout';
import PageHome from '@/pages/PageHome';
import PageReceiving from '@/pages/Receiving/PageReceiving';
import PageReceivingProducts from '@/pages/Receiving/PageReceivingDeliveries';
import PageReceivingScan from '@/pages/Receiving/PageReceivingScan';
import PagePrint from '@/pages/Print/PagePrint';
import PagePrintModeControl from '@/pages/Print/PagePrintModeControl';
import PageSale from '@/pages/Sale/PageSale';
import PageSaleItems from '@/pages/Sale/PageSaleItems';
import PageSettings from '@/pages/PageSettings';
import PageCycleCount from '@/pages/CycleCount/PageCycleCount';
import PageCycleCountMissingItems from '@/pages/CycleCount/PageCycleCountMissingItems';
import PageCycleCountFilterBrand from '@/pages/CycleCount/PageCycleCountFilterBrand';
import PageCycleCountFilterUPC from '@/pages/CycleCount/PageCycleCountFilterUpc';
import PageCycleCountFilterEPC from '@/pages/CycleCount/PageCycleCountFilterEpc';
import PageCycleCountFilterModel from '@/pages/CycleCount/PageCycleCountFilterModel';
import PageCycleCountFilterSummary from '@/pages/CycleCount/PageCycleCountFilterSummary';

import PageCycleCountFilterMarketing from '@/pages/CycleCount/PageCycleCountFilterMarketing';
import PageReceivingRecapConfirm from '@/pages/Receiving/PageReceivingRecapConfirm';
import PageCycleCountScanProducts from '@/pages/CycleCount/PageScanProducts';
import PagePoc from '@/pages/Poc/PagePoc';
import PagePocMigration from '@/pages/Poc/PagePocMigration';
import PageSohExtraction from '@/pages/PageSohExtraction';
import PageStoreToStoreHistory from '@/pages/StoreToStore/PageStoreToStoreHistory';
import PageStoreToStore from '@/pages/StoreToStore/PageStoreToStore';
import PageStoreToStoreSale from '@/pages/StoreToStore/PageStoreToStoreSale';
import PageRepricing from '@/pages/PageRepricing';
import PageSOH from '@/pages/PageSoh';
import PageControlAdmin from '@/pages/ControlPanel/PageControlAdmin';
import PageAddUpdateRole from '@/pages/ControlPanel/PageAddUpdateRole';

import PageControlUsers from '@/pages/ControlPanel/PageControlUsers';
import PageControlUsersCreate from '@/pages/ControlPanel/PageControlUsersCreate';
import PageControlUsersEdit from '@/pages/ControlPanel/PageControlUsersEdit';
import PageControlManageRole from '@/pages/ControlPanel/PageControlManageRole/index';

import PageControlStores from '@/pages/ControlPanel/PageControlStores';
import PageControlReasonCodes from '@/pages/ControlPanel/PageControlReasonCodes';
import { useSelector } from '@/hooks/useSelector';
import { update as BCupdate } from '@/features/breadcrumbs/breadcrumbsSlice';
import { update as userUpdate } from '@/features/user/userSlice';

import { Header } from '@/components/layout/Header';
import { HeaderLite } from '@/components/layout/HeaderLite';
import {
  isAdmin,
  isCycleCountAllowed,
  isPrintersAllowed,
  isReceivingAllowed,
  isRepricingAllowed,
  isSaleAllowed,
  isStockSummaryAllowed,
  isStoreTransferAllowed,
  isStoreMigrator,
  isControlPanelAllowed,
  isUsersManager,
  isRoleManager,
  isStoresManager,
  isReasonsManager,
  isSOHExtension,
  isReaderUploadAllowed,
  isDeleteEPCAllowed,
  isManualLoaderAllowed,
  isStoreMigratorAllowed,
  isBISAllowed,
} from '@/utils/user';
import { BreadcrumbsItem } from '@/types/breadcrumbs';
import { useAppDispatch } from './store';
import { useAsync } from 'react-use';
import { useTranslation } from 'react-i18next';
import PageStoreDetails from '@/pages/ControlPanel/PageStoreDetails/index';
import PageStoreAddDevice from '@/pages/ControlPanel/PageStoreAddDevice/index';
import { RoleModalContextProvider } from '@/context/roleModalContext';
import PageControlReasonCodesEdit from '@/pages/ControlPanel/PageControlReasonCodesEdit';
import PageControlReasonCodesCreate from '@/pages/ControlPanel/PageControlReasonCodesCreate';
import PageStoreDamagesIntro from '@/pages/StoreDamages/PageStoreDamagesIntro';
import { ReasonCodeContextProvider } from '@/context/reasonCodeContext';
import PageStoreDamagesCreate from '@/pages/StoreDamages/PageStoreDamagesCreate';
import PageStoreDamagesValidate from '@/pages/StoreDamages/PageStoreDamagesValidate';
import PageDownloadPDF from '@/pages/PageDownloadPDF';
import PageNotification from '@/pages/PageNotification';
import { NotificationContextProvider } from '@/context/notificationContext';
import PageRecall from '@/pages/PageRecall';
import PageRecallOrder from '@/pages/PageRecall/PageRecallOrder';
import { RecallContextProvider } from '@/context/recallContext';
import PageInventory from '@/pages/Inventory/PageInventory';
import PageInventoryScan from '@/pages/Inventory/PageInventoryScan';
import PageInventoryVirtualBuddy from '@/pages/Inventory/PageInventoryVirtualBuddy';
import PageInventoryVirtualBuddyScan from '@/pages/Inventory/PageInventoryVirtualBuddyScan';
import PageInventoryReport from '@/pages/Inventory/PageInventoryReport';
import { SocketContextProvider } from '@/context/socketContext';
import PageInventoryDetails from '@/pages/Inventory/PageInventoryDetails';
import PageManageDelivery from '@/pages/ManageDelivery/PageManageDelivery';
import PageManageDeliveryDetails from '@/pages/ManageDelivery/PageManageDeliveryDetails';
import PageUploadUserFile from '@/pages/PageUploadUserFile';
import { CycleCountContextProvider } from '@/context/cycleCount';
import PageStockSummaryDetails from '../pages/PageStockSummary/PageStockSummaryDetails';
import PageStockSummary from '../pages/PageStockSummary';
import { PrintContextProvider } from '@/context/Print';
import PageMaintenance from '@/pages/Maintenance';
import PageSaleExceptions from '@/pages/Sale/PageSaleExceptions';
import PageSaleExceptionsScan from '@/pages/Sale/PageSaleExceptionsScan';
import PagePosExceptions from '@/pages/Pos/PagePosExceptions';
import PageReturnsIntro from '@/pages/Return/PageReturnIntro';
import PageReturnsBackup from '@/pages/Return/PageReturnBackup';
import PageReturnPos from '@/pages/Return/PageReturnPos';
import PageStoreMigratorIntro from '@/pages/Loader/PageIntro';
import PageManualUpload from '@/pages/Loader/PageManualUpload';
import PageReaderUpload from '@/pages/Loader/PageReaderUpload';
import PageReaderUploadRecap from '@/pages/Loader/PageReaderUploadRecap';
import PageDeleteEpc from '@/pages/Loader/PageDeleteEpc';
import PageDeleteEpcDetails from '@/pages/Loader/PageDeleteEpcDetails';
import PageVasStore from '@/pages/Loader/PageVasStore';
import PageCycleCountIntro from '@/pages/CycleCount/PageCycleCountIntro';
import PageSohAlignment from '@/pages/CycleCount/PageSoh';
import PageSohAlignmentVerify from '@/pages/CycleCount/PageSohVerify';
import PageSohReport from '@/pages/CycleCount/PageSohReport';
import { ProcessService, ProcessStatus } from '@/api/receive';
import { ModalDataSavedError as Modal } from '@/components/layout/ModalDataSaved';
import { UIBox } from '@/components/ui/Box';
import { UIButton } from '@/components/ui/Button';
import PageBISItems from '@/pages/Sale/PageBISItems';

export enum AppRoutes {
  INTRO = '/',
  LOGIN = '/login',
  LOGOUT = '/logout',
  RECEIVING = '/receiving',
  RECEIVING_DELIVERIES = '/receiving/deliveries',
  SCAN = '/receiving/products/scan',
  CONFIRM_RECAP = '/receiving/products/confirmed',
  PRINT = '/print',
  PRINT_TAG = '/print/:mode',
  SALE = '/sale',
  SALE_ITEMS = '/sale/items',
  SALE_BIS = '/sale/back-to-stock',
  SALE_EXCEPTIONS = '/sale/exceptions',
  SALE_EXCEPTIONS_SCAN = '/sale/exceptions/scan',
  COUNTING_ITEMS = '/counting-items',
  CYCLE_COUNT = '/counting-items/cycle-count',
  CYCLE_COUNT_MISSING_ITEMS = '/counting-items/cycle-count/missing-items',
  CYCLE_COUNT_FILTER_BRAND = '/counting-items/cycle-count/filter-brand',
  CYCLE_COUNT_FILTER_MARKETING = '/counting-items/cycle-count/filter-marketing-theme',
  CYCLE_COUNT_FILTER_SUMMARY = '/counting-items/cycle-count/summary',
  CYCLE_COUNT_FILTER_PRODUCTS = '/counting-items/cycle-count/scan',
  CYCLE_COUNT_FILTER_UPC = '/counting-items/cycle-count/filter-UPC',
  CYCLE_COUNT_FILTER_EPC = '/counting-items/cycle-count/filter-EPC',
  CYCLE_COUNT_FILTER_MODEL = '/counting-items/cycle-count/filter-model',
  STORE_TRANSFER_HISTORY = '/store-transfer-history',
  STORE_TRANSFER = '/store-transfer',
  STORE_TRANSFER_SALE = '/store-transfer-sale',
  STOCK_SUMMARY = '/stock-summary',
  STOCK_SUMMARY_DETAILS = `/stock-summary/details`,
  REPRICING = '/repricing',
  SETTINGS = '/settings',
  POC = '/poc-migration',
  POC_DATA = '/poc-migration-data',
  STOCK_ON_HAND = '/download-soh',
  SOH_EXTACTION = '/soh-extraction',

  ADMIN = '/control-panel',
  MANAGE_USERS = '/manage-users',
  MANAGE_USERS_CREATE = '/manage-users/create',
  MANAGE_USERS_EDIT = '/manage-users/edit',
  MANAGE_USERS_UPLOAD = '/manage-users/upload',

  MANAGE_REASON_CODES = '/manage-reason-codes',
  MANAGE_REASON_CODES_CREATE = '/manage-reason-codes/create',
  MANAGE_REASON_CODES_EDIT = '/manage-reason-codes/:groupTitle/edit',

  MANAGE_ROLES = '/manage-roles',
  UPDATE_ROLE = '/manage-roles/:role/edit',
  CREATE_ROLE = '/manage-roles/add',

  MANAGE_STORES = '/manage-stores',
  STORE_DETAILS = '/manage-stores/:store',
  ADD_DEVICE = '/manage-stores/:store/add-device',
  UPDATE_DEVICE = '/manage-stores/:store/:device/edit',

  STORE_DAMAGES_INTRO = '/store-damages',
  STORE_DAMAGES_VALIDATE = '/store-damages/validate',
  STORE_DAMAGES_CREATE = '/store-damages/create',

  CUSTOMER_RETURNS_INTRO = '/customer-returns',
  CUSTOMER_RETURNS_POS = '/customer-returns/pos',
  CUSTOMER_RETURNS_BACKUP = '/customer-returns/backup',

  PDF_DOWNLOAD_CREATE_DAMAGE = '/store-damages/create/pdf-download',
  PDF_DOWNLOAD_VALIDATE_DAMAGE = '/store-damages/validate/pdf-download',
  PDF_DOWNLOAD_RETURN_DAMAGE = '/customer-returns/backup/pdf-download',
  PDF_DOWNLOAD_STORE_TRANSFER = '/store-transfer/pdf-download',
  NOTIFICATION = '/notification',

  RECALL = '/recall',
  RECALL_ORDER = '/recall/:idRecall',
  PDF_DOWNLOAD_RECALL = '/recall/order/pdf-download',

  INVENTORY = '/inventory',
  INVENTORY_RETRY = '/inventory-retry/:id',
  INVENTORY_SCAN = '/inventory/:id',
  INVENTORY_REPORT = '/inventory-report/:id',
  INVENTORY_DETAILS = '/inventory-details/:id',
  INVENTORY_VIRTUAL_BUDDY = '/inventory-virtual-buddy',
  INVENTORY_VIRTUAL_BUDDY_SCAN = '/inventory-virtual-buddy/:id',

  MANAGE_DELIVERY = '/manage-delivery',
  MANAGE_DELIVERY_DETAILS = '/manage-delivery/:storeCode/:deliveryNumber',

  MAINTENANCE = '/maintenance',

  STORE_MIGRATOR = '/store-migrator',
  UPLOAD_STORE_MANUAL = '/store-migrator/manual-store-upload',
  UPLOAD_VAS_STORE = '/store-migrator/vas-store-upload',
  UPLOAD_STORE_READER = '/store-migrator/reader-store-upload',
  UPLOAD_STORE_READER_RECAP = '/store-migrator/reader-store-upload/recap',
  DELETE_EPC = '/store-migrator/delete-epc',
  DELETE_EPC_DETAILS = '/store-migrator/delete-epc/:storeCode',

  POS_EXCEPTIONS = '/pos-exceptions',

  SOH_ALIGNMENT = '/counting-items/soh-alignment',
  SOH_ALIGNMENT_VERIFY = '/counting-items/soh-alignment/verify',
  SOH_ALIGNMENT_FILTER_BRAND = '/counting-items/soh-alignment/filter-brand',
  SOH_ALIGNMENT_FILTER_UPC = '/counting-items/soh-alignment/filter-upc',
  SOH_ALIGNMENT_FILTER_MARKETING = '/counting-items/soh-alignment/filter-marketing',
  SOH_ALIGNMENT_FILTER_SUMMARY = '/counting-items/soh-alignment/summary',
  SOH_ALIGNMENT_FILTER_PRODUCTS = '/counting-items/soh-alignment/scan',
  SOH_ALIGNMENT_REPORT = '/counting-items/soh-alignment/report',
}

export const Routes: React.FC = () => {
  const user = useSelector(state => state.user);

  const { t } = useTranslation();

  return (
    <Switch>
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.INTRO}
        component={PageHome}
      />

      {/* LOGIN */}
      <PageLayoutLite
        privateRoute={false}
        exact
        path={AppRoutes.LOGIN}
        component={PageLogin}
      />
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.LOGOUT}
        component={PageLogout}
      />

      {/* RECEIVING */}
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.RECEIVING}
        breadcrumb={[
          {
            title: t('page.receiving'),
          },
        ]}
        component={PageReceiving}
        isDisabled={!isReceivingAllowed(user)}
      />
      <PageLayoutDefault
        privateRoute
        path={AppRoutes.RECEIVING_DELIVERIES}
        breadcrumb={[
          {
            title: t('page.receiving'),
            path: AppRoutes.RECEIVING,
          },
          {
            title: t('deliveries'),
          },
        ]}
        exact
        component={PageReceivingProducts}
        isDisabled={!isReceivingAllowed(user)}
      />
      <PageLayoutDefault
        privateRoute
        path={AppRoutes.CONFIRM_RECAP}
        exact
        component={PageReceivingRecapConfirm}
        isDisabled={!isReceivingAllowed(user)}
      />

      {/* SCAN */}
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.SCAN}
        component={PageReceivingScan}
        isDisabled={!isReceivingAllowed(user)}
      />

      {/* PRINT */}
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.PRINT_TAG}
        component={PagePrint}
        isDisabled={!isPrintersAllowed(user)}
      />
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.PRINT}
        component={PagePrintModeControl}
        isDisabled={!isPrintersAllowed(user)}
      />

      {/* CYCLE COUNT */}
      <PageLayoutDefault
        privateRoute
        exact
        breadcrumb={[
          {
            title: t('page.countingItems'),
          },
        ]}
        path={AppRoutes.COUNTING_ITEMS}
        component={PageCycleCountIntro}
        isDisabled={!isCycleCountAllowed(user)}
      />
      <PageLayoutDefault
        privateRoute
        exact
        breadcrumb={[
          {
            title: t('page.countingItems'),
            path: AppRoutes.COUNTING_ITEMS,
          },
          {
            title: t('page.cyclecount'),
          },
        ]}
        path={AppRoutes.CYCLE_COUNT}
        component={PageCycleCount}
        isDisabled={!isCycleCountAllowed(user)}
      />
      <PageLayoutDefault
        privateRoute
        exact
        breadcrumb={[
          {
            title: t('page.countingItems'),
            path: AppRoutes.COUNTING_ITEMS,
          },
          {
            title: t('page.cyclecount'),
            path: AppRoutes.CYCLE_COUNT,
          },
          {
            title: t('page.missingitems'),
          },
        ]}
        path={AppRoutes.CYCLE_COUNT_MISSING_ITEMS}
        isDisabled={!isCycleCountAllowed(user)}
      >
        <CycleCountContextProvider>
          <PageCycleCountMissingItems />
        </CycleCountContextProvider>
      </PageLayoutDefault>
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.CYCLE_COUNT_FILTER_BRAND}
        component={PageCycleCountFilterBrand}
        isDisabled={!isCycleCountAllowed(user)}
      />
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.CYCLE_COUNT_FILTER_UPC}
        component={PageCycleCountFilterUPC}
        isDisabled={!isCycleCountAllowed(user)}
      />
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.CYCLE_COUNT_FILTER_EPC}
        component={PageCycleCountFilterEPC}
        isDisabled={!isCycleCountAllowed(user)}
      />
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.CYCLE_COUNT_FILTER_MODEL}
        component={PageCycleCountFilterModel}
        isDisabled={!isCycleCountAllowed(user)}
      />
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.CYCLE_COUNT_FILTER_MARKETING}
        component={PageCycleCountFilterMarketing}
        isDisabled={!isCycleCountAllowed(user)}
      />
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.CYCLE_COUNT_FILTER_SUMMARY}
        component={PageCycleCountFilterSummary}
        isDisabled={!isCycleCountAllowed(user)}
      />
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.SOH_ALIGNMENT}
        component={PageSohAlignment}
        isDisabled={!isCycleCountAllowed(user)}
      />
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.SOH_ALIGNMENT_VERIFY}
        component={PageSohAlignmentVerify}
        isDisabled={!isCycleCountAllowed(user)}
      />
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.SOH_ALIGNMENT_FILTER_BRAND}
        component={PageCycleCountFilterBrand}
        isDisabled={!isCycleCountAllowed(user)}
      />
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.SOH_ALIGNMENT_FILTER_UPC}
        component={PageCycleCountFilterUPC}
        isDisabled={!isCycleCountAllowed(user)}
      />
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.SOH_ALIGNMENT_FILTER_MARKETING}
        component={PageCycleCountFilterMarketing}
        isDisabled={!isCycleCountAllowed(user)}
      />
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.SOH_ALIGNMENT_FILTER_SUMMARY}
        component={PageCycleCountFilterSummary}
        isDisabled={!isCycleCountAllowed(user)}
      />

      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.SOH_ALIGNMENT_FILTER_PRODUCTS}
        isDisabled={!isCycleCountAllowed(user)}
      >
        <CycleCountContextProvider>
          <PageCycleCountScanProducts />
        </CycleCountContextProvider>
      </PageLayoutDefault>
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.SOH_ALIGNMENT_REPORT}
        component={PageSohReport}
        isDisabled={!isCycleCountAllowed(user)}
        breadcrumb={[
          {
            title: t('page.countingItems'),
            path: AppRoutes.COUNTING_ITEMS,
          },
          {
            title: t('page.sohReport'),
          },
        ]}
      />
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.CYCLE_COUNT_FILTER_PRODUCTS}
        isDisabled={!isCycleCountAllowed(user)}
      >
        <CycleCountContextProvider>
          <PageCycleCountScanProducts />
        </CycleCountContextProvider>
      </PageLayoutDefault>

      {/* STORE TO STORE */}
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.STORE_TRANSFER_HISTORY}
        component={PageStoreToStoreHistory}
        isDisabled={!isStoreTransferAllowed(user)}
        breadcrumb={[
          {
            title: t('page.store_to_store_history'),
          },
        ]}
      />
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.STORE_TRANSFER}
        component={PageStoreToStore}
        isDisabled={!isStoreTransferAllowed(user)}
        breadcrumb={[
          {
            title: t('page.store_to_store_history'),
            path: AppRoutes.STORE_TRANSFER_HISTORY,
          },
          {
            title: t('page.store_to_store'),
          },
        ]}
      />
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.STORE_TRANSFER_SALE}
        component={PageStoreToStoreSale}
        isDisabled={!isStoreTransferAllowed(user)}
        breadcrumb={[
          {
            title: t('page.store_to_store_history'),
            path: AppRoutes.STORE_TRANSFER_HISTORY,
          },
          {
            title: t('page.store_to_store'),
            path: AppRoutes.STORE_TRANSFER,
          },
          {
            title: t('page.store_to_store_send'),
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={false}
        privateRoute
        exact
        path={AppRoutes.PDF_DOWNLOAD_STORE_TRANSFER}
        component={PageDownloadPDF}
        breadcrumb={[
          {
            title: t('page.store_to_store'),
          },
          {
            title: t('page.store_to_store_send'),
          },
          {
            title: t('page.downloadPDF'),
          },
        ]}
      />

      {/* SALE */}
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.SALE}
        component={PageSale}
        isDisabled={!isSaleAllowed(user)}
        breadcrumb={[
          {
            title: t('page.sale'),
          },
        ]}
      />
      <PageLayoutDefault
        privateRoute
        exact
        path={`${AppRoutes.SALE_ITEMS}`}
        component={PageSaleItems}
        isDisabled={!isSaleAllowed(user)}
        breadcrumb={[
          {
            path: AppRoutes.SALE,
            title: t('page.sale'),
          },
          { title: t('page.saleItems') },
        ]}
      />
      <PageLayoutDefault
        privateRoute
        exact
        path={`${AppRoutes.SALE_EXCEPTIONS}`}
        component={PageSaleExceptions}
        isDisabled={!isSaleAllowed(user)}
        breadcrumb={[
          {
            path: AppRoutes.SALE,
            title: t('page.sale'),
          },
          { title: t('page.saleExceptions') },
        ]}
      />
      <PageLayoutDefault
        privateRoute
        exact
        path={`${AppRoutes.SALE_EXCEPTIONS_SCAN}`}
        component={PageSaleExceptionsScan}
        isDisabled={!isSaleAllowed(user)}
        breadcrumb={[
          {
            path: AppRoutes.SALE,
            title: t('page.sale'),
          },
          {
            path: AppRoutes.SALE_EXCEPTIONS,
            title: t('page.saleExceptions'),
          },
          { title: t('page.saleExceptionsScan') },
        ]}
      />
      <PageLayoutDefault
        privateRoute
        exact
        path={`${AppRoutes.SALE_BIS}`}
        component={PageBISItems}
        isDisabled={!isBISAllowed(user)}
        breadcrumb={[
          {
            path: AppRoutes.SALE,
            title: t('page.sale'),
          },
          { title: t('page.bis') },
        ]}
      />
      <PageLayoutDefault
        privateRoute
        exact
        path={`${AppRoutes.SETTINGS}`}
        component={PageSettings}
      />

      {/* STOCK SUMMARY */}
      <PageLayoutDefault
        privateRoute
        exact
        path={`${AppRoutes.STOCK_SUMMARY}`}
        component={PageStockSummary}
        isDisabled={!isStockSummaryAllowed(user)}
      />

      <PageLayoutDefault
        privateRoute
        exact
        path={`${AppRoutes.STOCK_SUMMARY_DETAILS}`}
        component={PageStockSummaryDetails}
        isDisabled={!isStockSummaryAllowed(user)}
      />

      {/* REPRICING */}
      <PageLayoutDefault
        privateRoute
        exact
        path={`${AppRoutes.REPRICING}`}
        component={PageRepricing}
        isDisabled={!isRepricingAllowed(user)}
      />

      {/* POC */}
      <PageLayoutDefault
        privateRoute
        exact
        path={`${AppRoutes.POC}`}
        component={PagePoc}
        isDisabled={!isAdmin(user)}
      />
      <PageLayoutDefault
        privateRoute
        exact
        path={`${AppRoutes.POC_DATA}`}
        component={PagePocMigration}
        isDisabled={!isAdmin(user)}
      />

      {/* SOH */}
      <PageLayoutDefault
        privateRoute
        exact
        path={`${AppRoutes.STOCK_ON_HAND}`}
        component={PageSOH}
      />

      {/* STORE MIGRATOR */}
      <PageLayoutDefault
        isDisabled={!isStoreMigratorAllowed(user)}
        privateRoute
        exact
        path={AppRoutes.STORE_MIGRATOR}
        component={PageStoreMigratorIntro}
        breadcrumb={[
          {
            title: t('user.store_migrator'),
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={!isManualLoaderAllowed(user)}
        privateRoute
        exact
        path={AppRoutes.UPLOAD_STORE_MANUAL}
        component={PageManualUpload}
        breadcrumb={[
          {
            title: t('user.store_migrator'),
            path: AppRoutes.STORE_MIGRATOR,
          },
          {
            title: t('page.uploadStoreManual'),
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={!isReaderUploadAllowed(user)}
        privateRoute
        exact
        path={AppRoutes.UPLOAD_STORE_READER}
        component={PageReaderUpload}
        breadcrumb={[
          {
            title: t('user.store_migrator'),
            path: AppRoutes.STORE_MIGRATOR,
          },
          {
            title: t('page.uploadStoreReader'),
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={!isReaderUploadAllowed(user)}
        privateRoute
        exact
        path={AppRoutes.UPLOAD_STORE_READER_RECAP}
        component={PageReaderUploadRecap}
        breadcrumb={[
          {
            title: t('user.store_migrator'),
            path: AppRoutes.STORE_MIGRATOR,
          },
          {
            title: t('page.uploadStoreReader'),
          },
          {
            title: t('page.uploadStoreReaderRecap'),
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={!isDeleteEPCAllowed(user)}
        privateRoute
        exact
        path={AppRoutes.DELETE_EPC}
        component={PageDeleteEpc}
        breadcrumb={[
          {
            title: t('user.store_migrator'),
            path: AppRoutes.STORE_MIGRATOR,
          },
          {
            title: t('page.deleteEpc'),
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={!isDeleteEPCAllowed(user)}
        privateRoute
        exact
        path={AppRoutes.DELETE_EPC_DETAILS}
        component={PageDeleteEpcDetails}
        breadcrumb={[
          {
            title: t('user.store_migrator'),
            path: AppRoutes.STORE_MIGRATOR,
          },
          {
            title: t('page.deleteEpc'),
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={!isStoreMigrator(user)}
        privateRoute
        exact
        path={AppRoutes.UPLOAD_VAS_STORE}
        component={PageVasStore}
        breadcrumb={[
          {
            title: t('user.store_migrator'),
          },
        ]}
      />

      {/* USER PROFILING */}
      <PageLayoutDefault
        isDisabled={!isControlPanelAllowed(user)}
        privateRoute
        exact
        breadcrumb={[
          {
            title: t('page.controlPanel'),
          },
        ]}
        path={`${AppRoutes.ADMIN}`}
        component={PageControlAdmin}
      />
      <PageLayoutDefault
        isDisabled={!isUsersManager(user)}
        privateRoute
        exact
        breadcrumb={[
          {
            title: t('page.controlPanel'),
            path: AppRoutes.ADMIN,
          },
          {
            title: t('menu.users'),
          },
        ]}
        path={`${AppRoutes.MANAGE_USERS}`}
        component={PageControlUsers}
      >
        <RoleModalContextProvider>
          <PageControlUsers />
        </RoleModalContextProvider>
      </PageLayoutDefault>
      <PageLayoutDefault
        isDisabled={!isAdmin(user)}
        privateRoute
        exact
        breadcrumb={[
          {
            title: t('page.controlPanel'),
            path: AppRoutes.ADMIN,
          },
          {
            title: t('menu.users'),
            path: AppRoutes.MANAGE_USERS,
          },
          {
            title: t('menu.users'),
          },
        ]}
        path={`${AppRoutes.MANAGE_USERS_UPLOAD}`}
        component={PageUploadUserFile}
      />
      <PageLayoutDefault
        isDisabled={!isUsersManager(user)}
        privateRoute
        exact
        breadcrumb={[
          {
            title: t('page.controlPanel'),
            path: AppRoutes.ADMIN,
          },
          {
            title: t('menu.users'),
            path: AppRoutes.MANAGE_USERS,
          },
          {
            title: t('page.createUser'),
          },
        ]}
        path={`${AppRoutes.MANAGE_USERS_CREATE}`}
      >
        <RoleModalContextProvider>
          <PageControlUsersCreate />
        </RoleModalContextProvider>
      </PageLayoutDefault>
      <PageLayoutDefault
        isDisabled={!isUsersManager(user)}
        privateRoute
        exact
        breadcrumb={[
          {
            title: t('page.controlPanel'),
            path: AppRoutes.ADMIN,
          },
          {
            title: t('menu.users'),
            path: AppRoutes.MANAGE_USERS,
          },
          {
            title: t('page.editUser'),
          },
        ]}
        path={`${AppRoutes.MANAGE_USERS_EDIT}`}
      >
        <RoleModalContextProvider>
          <PageControlUsersEdit />
        </RoleModalContextProvider>
      </PageLayoutDefault>
      {/*MANAGE ROLES */}
      <PageLayoutDefault
        isDisabled={!isRoleManager(user)}
        privateRoute
        exact
        breadcrumb={[
          {
            title: t('page.controlPanel'),
            path: AppRoutes.ADMIN,
          },
          {
            title: t('menu.manageRoles'),
          },
        ]}
        path={`${AppRoutes.MANAGE_ROLES}`}
        component={PageControlManageRole}
      />
      {/*UPDATE_ROLE */}
      <PageLayoutDefault
        isDisabled={!isRoleManager(user)}
        privateRoute
        exact
        breadcrumb={[
          {
            title: t('page.controlPanel'),
            path: AppRoutes.ADMIN,
          },
          {
            title: t('menu.manageRoles'),
            path: AppRoutes.MANAGE_ROLES,
          },
          {
            title: ':role',
            path: AppRoutes.UPDATE_ROLE,
          },
        ]}
        path={AppRoutes.UPDATE_ROLE}
        component={PageAddUpdateRole}
      />
      <PageLayoutDefault
        isDisabled={!isRoleManager(user)}
        privateRoute
        exact
        breadcrumb={[
          {
            title: t('page.controlPanel'),
            path: AppRoutes.ADMIN,
          },
          {
            title: t('menu.manageRoles'),
            path: AppRoutes.MANAGE_ROLES,
          },
          {
            title: t('create'),
          },
        ]}
        path={AppRoutes.CREATE_ROLE}
        component={PageAddUpdateRole}
      />
      <PageLayoutDefault
        isDisabled={!isStoresManager(user)}
        privateRoute
        exact
        breadcrumb={[
          {
            title: t('page.controlPanel'),
            path: AppRoutes.ADMIN,
          },
          {
            title: t('menu.stores'),
          },
        ]}
        path={`${AppRoutes.MANAGE_STORES}`}
        component={PageControlStores}
      />
      <PageLayoutDefault
        isDisabled={!isSOHExtension(user)}
        privateRoute
        exact
        breadcrumb={[
          {
            title: t('menu.SoHExtraction'),
          },
        ]}
        path={`${AppRoutes.SOH_EXTACTION}`}
        component={PageSohExtraction}
      />
      <PageLayoutDefault
        isDisabled={!isReasonsManager(user)}
        privateRoute
        exact
        breadcrumb={[
          {
            title: t('page.controlPanel'),
            path: AppRoutes.ADMIN,
          },
          {
            title: t('menu.manageReasonCodes'),
          },
        ]}
        path={`${AppRoutes.MANAGE_REASON_CODES}`}
      >
        <ReasonCodeContextProvider>
          <PageControlReasonCodes />
        </ReasonCodeContextProvider>
      </PageLayoutDefault>
      <PageLayoutDefault
        isDisabled={!isReasonsManager(user)}
        privateRoute
        exact
        breadcrumb={[
          {
            title: t('page.controlPanel'),
            path: AppRoutes.ADMIN,
          },
          {
            title: t('menu.manageReasonCodes'),
            path: AppRoutes.MANAGE_REASON_CODES,
          },
          {
            title: ':groupTitle',
            path: AppRoutes.MANAGE_REASON_CODES_EDIT,
          },
        ]}
        path={`${AppRoutes.MANAGE_REASON_CODES_EDIT}`}
      >
        <ReasonCodeContextProvider>
          <PageControlReasonCodesEdit />
        </ReasonCodeContextProvider>
      </PageLayoutDefault>
      <PageLayoutDefault
        isDisabled={!isReasonsManager(user)}
        privateRoute
        exact
        breadcrumb={[
          {
            title: t('page.controlPanel'),
            path: AppRoutes.ADMIN,
          },
          {
            title: t('menu.manageReasonCodes'),
            path: AppRoutes.MANAGE_REASON_CODES,
          },
          {
            title: t('create'),
            path: AppRoutes.MANAGE_REASON_CODES_CREATE,
          },
        ]}
        path={`${AppRoutes.MANAGE_REASON_CODES_CREATE}`}
      >
        <ReasonCodeContextProvider>
          <PageControlReasonCodesCreate />
        </ReasonCodeContextProvider>
      </PageLayoutDefault>
      <PageLayoutDefault
        isDisabled={!isStoresManager(user)}
        privateRoute
        exact
        breadcrumb={[
          {
            title: t('page.controlPanel'),
            path: AppRoutes.ADMIN,
          },
          {
            title: t('menu.stores'),
            path: AppRoutes.MANAGE_STORES,
          },
          {
            title: ':store',
            path: AppRoutes.STORE_DETAILS,
          },
        ]}
        path={AppRoutes.STORE_DETAILS}
        component={PageStoreDetails}
      />
      <PageLayoutDefault
        isDisabled={!isStoresManager(user)}
        privateRoute
        exact
        path={AppRoutes.ADD_DEVICE}
        breadcrumb={[
          {
            title: t('page.controlPanel'),
            path: AppRoutes.ADMIN,
          },
          {
            title: t('menu.stores'),
            path: AppRoutes.MANAGE_STORES,
          },
          {
            title: ':store',
            path: AppRoutes.STORE_DETAILS,
          },
          { title: t('add') },
        ]}
        component={PageStoreAddDevice}
      />
      <PageLayoutDefault
        isDisabled={!isStoresManager(user)}
        privateRoute
        path={AppRoutes.UPDATE_DEVICE}
        component={PageStoreAddDevice}
        breadcrumb={[
          {
            title: t('page.controlPanel'),
            path: AppRoutes.ADMIN,
          },
          {
            title: t('menu.stores'),
            path: AppRoutes.MANAGE_STORES,
          },
          {
            title: ':store',
            path: AppRoutes.STORE_DETAILS,
          },
          {
            title: ':device',
            path: AppRoutes.UPDATE_DEVICE,
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={false}
        privateRoute
        exact
        path={AppRoutes.STORE_DAMAGES_INTRO}
        component={PageStoreDamagesIntro}
        breadcrumb={[
          {
            title: t('page.inStoreDamages'),
            path: AppRoutes.STORE_DAMAGES_INTRO,
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={false}
        privateRoute
        exact
        path={AppRoutes.STORE_DAMAGES_CREATE}
        component={PageStoreDamagesCreate}
        breadcrumb={[
          {
            title: t('page.inStoreDamages'),
            path: AppRoutes.STORE_DAMAGES_INTRO,
          },
          {
            title: t('page.createDamages'),
            path: AppRoutes.STORE_DAMAGES_CREATE,
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={false}
        privateRoute
        exact
        component={PageStoreDamagesValidate}
        path={AppRoutes.STORE_DAMAGES_VALIDATE}
        breadcrumb={[
          {
            title: t('page.inStoreDamages'),
            path: AppRoutes.STORE_DAMAGES_INTRO,
          },
          {
            title: t('page.validateDamages'),
            path: AppRoutes.STORE_DAMAGES_VALIDATE,
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={false}
        privateRoute
        exact
        path={AppRoutes.PDF_DOWNLOAD_CREATE_DAMAGE}
        component={PageDownloadPDF}
        breadcrumb={[
          {
            title: t('page.inStoreDamages'),
          },
          {
            title: t('page.createDamages'),
          },
          {
            title: t('page.downloadPDF'),
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={false}
        privateRoute
        exact
        path={AppRoutes.PDF_DOWNLOAD_VALIDATE_DAMAGE}
        component={PageDownloadPDF}
        breadcrumb={[
          {
            title: t('page.inStoreDamages'),
          },
          {
            title: t('page.validateDamages'),
          },
          {
            title: t('page.downloadPDF'),
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={false}
        privateRoute
        exact
        path={AppRoutes.CUSTOMER_RETURNS_INTRO}
        component={PageReturnsIntro}
        breadcrumb={[
          {
            title: t('page.returns'),
            path: AppRoutes.CUSTOMER_RETURNS_INTRO,
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={false}
        privateRoute
        exact
        path={AppRoutes.CUSTOMER_RETURNS_BACKUP}
        component={PageReturnsBackup}
        breadcrumb={[
          {
            title: t('page.returns'),
            path: AppRoutes.CUSTOMER_RETURNS_INTRO,
          },
          {
            title: t('page.returns.backup'),
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={false}
        privateRoute
        exact
        path={AppRoutes.CUSTOMER_RETURNS_POS}
        component={PageReturnPos}
        breadcrumb={[
          {
            title: t('page.returns'),
            path: AppRoutes.CUSTOMER_RETURNS_INTRO,
          },
          {
            title: t('page.returns.pos'),
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={false}
        privateRoute
        exact
        path={AppRoutes.PDF_DOWNLOAD_RETURN_DAMAGE}
        component={PageDownloadPDF}
        breadcrumb={[
          {
            title: t('page.returns'),
          },
          {
            title: t('page.returns.backup'),
          },
          {
            title: t('page.downloadPDF'),
          },
        ]}
      />
      <PageLayoutDefault
        privateRoute
        exact
        path={AppRoutes.NOTIFICATION}
        breadcrumb={[
          {
            title: t('page.notification'),
            path: AppRoutes.NOTIFICATION,
          },
        ]}
        component={PageNotification}
      />
      <PageLayoutDefault
        isDisabled={false}
        privateRoute
        exact
        path={AppRoutes.RECALL}
        breadcrumb={[
          {
            title: t('page.recall'),
          },
        ]}
      >
        <RecallContextProvider>
          <PageRecall />
        </RecallContextProvider>
      </PageLayoutDefault>
      <PageLayoutDefault
        isDisabled={false}
        privateRoute
        exact
        path={AppRoutes.RECALL_ORDER}
        breadcrumb={[
          {
            title: t('page.recall'),
            path: AppRoutes.RECALL,
          },
          {
            title: t('page.recallOrder'),
          },
        ]}
      >
        <RecallContextProvider>
          <PageRecallOrder />
        </RecallContextProvider>
      </PageLayoutDefault>
      <PageLayoutDefault
        isDisabled={false}
        privateRoute
        exact
        path={AppRoutes.PDF_DOWNLOAD_RECALL}
        component={PageDownloadPDF}
        breadcrumb={[
          {
            title: t('page.recall'),
          },
          {
            title: t('page.recallOrder'),
          },
          {
            title: t('page.downloadPDF'),
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={false}
        privateRoute
        exact
        path={AppRoutes.INVENTORY}
        component={PageInventory}
        breadcrumb={[
          {
            title: t('page.inventory'),
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={false}
        privateRoute
        exact
        path={AppRoutes.INVENTORY_RETRY}
        component={PageInventoryReport}
        breadcrumb={[
          {
            title: t('page.inventory'),
          },
          {
            title: t('page.inventoryRetry'),
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={false}
        privateRoute
        exact
        path={AppRoutes.INVENTORY_SCAN}
        component={PageInventoryScan}
        breadcrumb={[
          {
            title: t('page.inventory'),
            path: AppRoutes.INVENTORY,
          },
          {
            title: t('scan'),
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={false}
        privateRoute
        exact
        path={AppRoutes.INVENTORY_DETAILS}
        component={PageInventoryDetails}
        breadcrumb={[
          {
            title: t('page.inventory'),
            path: AppRoutes.INVENTORY,
          },
          {
            title: t('page.inventoryDetails'),
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={false}
        privateRoute
        exact
        path={AppRoutes.INVENTORY_REPORT}
        component={PageInventoryReport}
        breadcrumb={[
          {
            title: t('page.inventory'),
          },
          {
            title: t('page.inventory.report'),
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={false}
        privateRoute
        exact
        path={AppRoutes.INVENTORY_VIRTUAL_BUDDY}
        component={PageInventoryVirtualBuddy}
        breadcrumb={[
          {
            title: t('page.inventory'),
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={false}
        privateRoute
        exact
        path={AppRoutes.INVENTORY_VIRTUAL_BUDDY_SCAN}
        component={PageInventoryVirtualBuddyScan}
        breadcrumb={[
          {
            title: t('page.inventory'),
            path: AppRoutes.INVENTORY_VIRTUAL_BUDDY,
          },
          {
            title: t('scan'),
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={false}
        privateRoute
        exact
        path={AppRoutes.MANAGE_DELIVERY}
        component={PageManageDelivery}
        breadcrumb={[
          {
            title: t('page.manageDelivery'),
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={false}
        privateRoute
        exact
        path={AppRoutes.MANAGE_DELIVERY_DETAILS}
        component={PageManageDeliveryDetails}
        breadcrumb={[
          {
            title: t('page.manageDelivery'),
            path: AppRoutes.MANAGE_DELIVERY,
          },
          {
            title: t('page.manageDeliveryDetails'),
          },
        ]}
      />
      <PageLayoutDefault
        isDisabled={false}
        privateRoute
        exact
        path={AppRoutes.POS_EXCEPTIONS}
        component={PagePosExceptions}
        breadcrumb={[
          {
            title: t('page.posExceptions'),
          },
        ]}
      />
      <PageLayoutLite
        isDisabled={false}
        exact
        path={AppRoutes.MAINTENANCE}
        component={PageMaintenance}
      />
    </Switch>
  );
};

export type AppFunctionalities = {
  functionalitiesDisabled?: boolean;
  process?: ProcessStatus;
};

const PrivateRoute = (props: RouteProps): ReactElement => {
  const { component, ...rest } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const Component = props.component;

  const [processStatus, setProcessStatus] = React.useState<ProcessStatus>();
  const [modalLockedTitle, setModalLockedTitle] = React.useState<string>();
  const [onGoingModalIsVisible, setOnGoingModalVisibility] =
    React.useState<boolean>(false);
  const storeCode = useSelector(
    state => state.currentStore.store?.storeCode || ''
  );
  const bis = useSelector(state => state.bis);
  const loggedUser = useSelector(state => state.user);

  useAsync(async () => {
    // Users can operate on these pages if there is a locked process
    if (
      props.path === AppRoutes.MAINTENANCE ||
      props.path === AppRoutes.SETTINGS ||
      props.path?.includes(AppRoutes.STORE_MIGRATOR)
    ) {
      return;
    }

    const readerUpload = await ProcessService.processReadProcessStatus({
      storeCode,
      filterByStoreCode: true,
      process: 'RSUP',
    });

    const manualUpload = await ProcessService.processReadProcessStatus({
      storeCode,
      filterByStoreCode: true,
      process: 'MSUP',
    });

    const sohAlignment = await ProcessService.processReadProcessStatus({
      storeCode,
      filterByStoreCode: true,
      process: 'SOHA',
    });

    const backInStock: ProcessStatus[] =
      await ProcessService.processReadProcessStatus({
        storeCode,
        filterByStoreCode: true,
        process: 'BIS',
      });

    if (readerUpload && readerUpload[0].isLocked) {
      setProcessStatus(readerUpload[0]);
      history.push(AppRoutes.INTRO);

      setOnGoingModalVisibility(true);
      return;
    }

    if (manualUpload && manualUpload[0].isLocked) {
      setProcessStatus(manualUpload[0]);
      history.push(AppRoutes.INTRO);

      setOnGoingModalVisibility(true);
      return;
    }

    if (sohAlignment && sohAlignment[0].isLocked) {
      setProcessStatus(sohAlignment[0]);

      if (!props.path?.includes(AppRoutes.COUNTING_ITEMS)) {
        history.push(AppRoutes.INTRO);
      }

      if (props.path === AppRoutes.INTRO) {
        setOnGoingModalVisibility(true);
        setModalLockedTitle(t('payAttention'));
      }

      return;
    }

    if (backInStock && backInStock[0].isLocked) {
      setProcessStatus(backInStock[0]);

      if (
        !props.path?.includes(AppRoutes.SALE_BIS) &&
        loggedUser.username !== backInStock[0].lockedBy
      ) {
        history.push(AppRoutes.INTRO);
      }

      if (props.path === AppRoutes.INTRO && !bis.avoidAlertInHome) {
        setOnGoingModalVisibility(true);
        setModalLockedTitle(t('payAttention'));
      }

      return;
    }

    setProcessStatus(undefined);
  }, [props.path]);

  return (
    <Route
      {...rest}
      render={(): ReactElement => {
        const userToken = sessionStorage.getItem('userToken');

        return userToken ? (
          <>
            <Modal
              iconType="ERROR"
              $minWidth="500px"
              $minHeight="250px"
              title={modalLockedTitle ?? t('uploadOnGoing')}
              message={t('functionalitiesDisabled', {
                process: t(processStatus?.process || ''),
                lockedBy: processStatus?.lockedBy || '',
              })}
              open={onGoingModalIsVisible}
              onClose={(): void => setOnGoingModalVisibility(false)}
            >
              <UIBox mt={4} alignItems="center" width="60%" alignSelf="center">
                <UIButton
                  outlined
                  label={t('ok')}
                  onClick={(): void => setOnGoingModalVisibility(false)}
                />
              </UIBox>
            </Modal>

            {/* @ts-ignore */}
            <Component
              process={processStatus}
              functionalitiesDisabled={processStatus?.isLocked}
            />
          </>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
              search: props.location?.search,
            }}
          />
        );
      }}
    />
  );
};

interface PageLayoutProps extends RouteProps {
  privateRoute?: boolean;
  isDisabled?: boolean;
  breadcrumb?: Array<BreadcrumbsItem>;
}

const PageLayoutDefault = ({
  privateRoute = false,
  isDisabled = false,
  breadcrumb = [],
  ...props
}: PageLayoutProps): ReactElement => {
  const dispatch = useAppDispatch();
  const user = useSelector(state => state.user);

  useAsync(async () => {
    if (breadcrumb.length > 0) {
      await dispatch(BCupdate(breadcrumb));
    }
  }, [dispatch, props.path]);

  if (isDisabled) {
    dispatch(
      userUpdate({
        ...user,
        errorCode: 'PERMISSION_DENIED',
        loginError: true,
      })
    );

    return (
      <Redirect
        to={{
          pathname: AppRoutes.INTRO,
          state: { from: props.location },
          search: props.location?.search,
        }}
      />
    );
  }

  return (
    <SocketContextProvider>
      <NotificationContextProvider>
        <PrintContextProvider>
          <Header color="default" position="sticky" />
          {privateRoute ? <PrivateRoute {...props} /> : <Route {...props} />}
        </PrintContextProvider>
      </NotificationContextProvider>
    </SocketContextProvider>
  );
};

const PageLayoutLite = ({
  privateRoute = false,
  ...props
}: PageLayoutProps): ReactElement => (
  <>
    <HeaderLite color="default" position="sticky" />
    {privateRoute ? <Route {...props} /> : <Route {...props} />}
  </>
);
