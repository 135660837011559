import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import type { RepriceInitialState } from '@/types/reprice';
import {
  ApiError,
  Layout,
  PrintService as BFFPrintService,
  RfidPrintIn,
} from '@/api/bff';
import type { RootState } from '@/app/rootReducer';
import {
  Reprice,
  RepriceRestService,
  RepriceUpc,
  PrintService,
  TypeOfStore,
  TypePrice,
} from '@/api/receive';
import getCurrentDate from '@/utils/getCurrentDate';
import { AppDispatch } from '@/app/store';
// import { Printer } from '@/types/store';
import {
  REPRICE_DAYS_TO_SUBTRACT,
  REPRICE_DAYS_TO_SUM,
} from '@/configs/settings';
import { handlePrintBody } from '@/utils/handlePrintLayout';

const initialState: RepriceInitialState = {
  data: {
    dateRepriceList: [],
  },
  currentDate: '',
  dataIsLoading: undefined,
  dataHasError: undefined,
  printIsLoading: undefined,
  printHasError: undefined,
};

type PromisesResultType = {
  rejected: number[];
  fullfilled: number[];
  listKeyDone: string[];
  listKeyFailure: string[];
};

const getProductPriceKeys = (
  index: number,
  list: string[],
  products: RepriceUpc[]
): string[] => {
  const { firstPrice, secondPrice } = products[index];

  const printHasDoneStatus = products[index].statusPrint === 'done';

  if (printHasDoneStatus) {
    return [''];
  } else {
    return [
      ...new Set([
        ...list,
        firstPrice?.keyFirstPrice ? firstPrice.keyFirstPrice : '',
        secondPrice?.keySecondPrice ? secondPrice.keySecondPrice : '',
      ]),
    ];
  }
};

export const getRepriceBrands = createAsyncThunk<
  Reprice,
  void,
  {
    state: RootState;
    rejectValue: ApiError;
  }
>('reprice/getRepriceBrands', async (_, { getState }) => {
  const { store } = getState().currentStore;
  const dateReprice = getCurrentDate();

  if (store?.storeCode) {
    const response = await RepriceRestService.repriceSearchProductToReprice({
      requestBody: {
        storeCode: store?.storeCode,
        dateReprice,
        daysToSubract: REPRICE_DAYS_TO_SUBTRACT,
        daysToSum: REPRICE_DAYS_TO_SUM,
      },
    });

    if (response) {
      return response;
    }
  }

  return {
    dateRepriceList: [],
  };
});

export const getProductsPrintStatus = createAsyncThunk<
  Reprice,
  {
    requestBody: Omit<
      Parameters<
        typeof RepriceRestService.repriceRepricePrintStatus
      >[number]['requestBody'],
      'storeCode' | 'dateReprice'
    >;
  },
  {
    state: RootState;
    rejectValue: ApiError;
  }
>(
  'reprice/getProductsPrintStatus',
  async (
    { requestBody: { listKeyDone, listKeyFailure } },
    { getState, rejectWithValue }
  ) => {
    try {
      const dateReprice = getCurrentDate();
      const { store } = getState().currentStore;

      if (store?.storeCode) {
        return await RepriceRestService.repriceRepricePrintStatus({
          requestBody: {
            storeCode: store?.storeCode,
            dateReprice,
            listKeyDone,
            listKeyFailure,
            daysToSubract: REPRICE_DAYS_TO_SUBTRACT,
            daysToSum: REPRICE_DAYS_TO_SUM,
          },
        });
      }
    } catch (err) {
      return rejectWithValue(err as ApiError);
    }
  }
);

export const printRepriceProducts = createAsyncThunk<
  void,
  {
    products: RepriceUpc[];
    upcPrintQuantity: Map<string, number>;
    selectedPrinter?: string;
  },
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: ApiError;
  }
>(
  'reprice/printRepriceProducts',
  async (
    { products, upcPrintQuantity, selectedPrinter },
    { getState, rejectWithValue, dispatch }
  ) => {
    const { store } = getState().currentStore;
    const printers = getState().currentStore.store?.printers || [];

    const repricePrint = async (
      product: RepriceUpc
      // storeCode: string
      // printers: Printer[]
    ): Promise<BFFPrintService> => {
      const {
        // brandDescription,
        // styleName,
        upcCode,
        firstPrice,
        secondPrice,
        statusPrint,
        numberItems,
      } = product;

      let layout = Layout.SRO_EMEA_RE_PRICING;

      if (product.firstPrice && product.firstPrice.currency === 'USD') {
        layout = Layout.SRO_NA_RE_PRICING;
      } else if (
        product.secondPrice &&
        product.secondPrice.currency === 'USD'
      ) {
        layout = Layout.SRO_NA_RE_PRICING;
      }

      const { typeOfStore } = store!;
      const date = getCurrentDate();

      const productToPrint =
        await PrintService.receiveshipFindProductItemPrintDetails({
          upcCode: upcCode,
          storeCode: store?.storeCode || '',
          date: date,
        });

      const additionalBody: RfidPrintIn = {
        layout,
        upcCode: upcCode,
        serialNumberPrinter: selectedPrinter || printers[0].printerCode,
        copy_quantity:
          statusPrint !== 'not printed'
            ? upcPrintQuantity.get(upcCode) || 1
            : numberItems,
      };

      const printRequestBody = handlePrintBody(
        typeOfStore || TypeOfStore.AIRPORT_US,
        {
          ...productToPrint,
          retailPrice: firstPrice?.price,
          outletPrice:
            secondPrice?.type === TypePrice.OUTL
              ? secondPrice.price
              : undefined,
          airportPrice:
            secondPrice?.type === TypePrice.AIRP
              ? secondPrice.price
              : undefined,
          description: secondPrice?.description,
          percentage: secondPrice?.percentage,
          currency: firstPrice
            ? firstPrice.currency
            : secondPrice
            ? secondPrice.currency
            : undefined,
        },
        additionalBody
      );

      return await BFFPrintService.print({
        requestBody: {
          storeCode: store?.storeCode || '',
          ...printRequestBody,
        },
      });

      // return BFFPrintService.print({
      // 	requestBody: {
      // 		van: productToPrint.van,
      // 		marketingThemeDescription: productToPrint.marketingThemeDescription,
      // 		marketingStoryDescription: productToPrint.marketingStoryDescription,
      // 		frameColor: productToPrint.frameColor,
      // 		lensColor: productToPrint.lensColor,
      // 		polarCode: productToPrint.polarCode,
      // 		brandDescription: brandDescription,
      // 		styleNameDescription: styleName,
      // 		storeCode: storeCode,
      // 		layout: layout as Print['layout'],
      // 		upcCode,
      // 		price: firstPrice ? firstPrice.price : undefined,
      // 		currency: firstPrice
      // 			? firstPrice.currency
      // 			: secondPrice
      // 				? secondPrice.currency
      // 				: undefined,
      // 		second_price: secondPrice ? secondPrice.price : undefined,
      // 		serialNumberPrinter:
      // 			selectedPrinter || printers?.[0].printerCode || '',
      // 		copy_quantity:
      // 			statusPrint !== 'not printed'
      // 				? upcPrintQuantity.get(upcCode) || 1
      // 				: numberItems,
      // 	},
      // });
    };

    try {
      await Promise.allSettled(
        products.map((product): Promise<BFFPrintService> | undefined => {
          if (store?.storeCode) {
            return repricePrint(product);
            // if (store.printers) {
            // 	return repricePrint(product, store.storeCode, store.printers);
            // }
          }
          return undefined;
        })
      ).then(results => {
        const promises = results.reduce<PromisesResultType>(
          (prev, curr, index) => {
            if (curr.status === 'fulfilled') {
              const listKeyDone = [...prev.fullfilled, index]
                .flatMap(i =>
                  getProductPriceKeys(i, prev.listKeyDone, products)
                )
                .filter(key => key !== '');

              return {
                ...prev,
                fullfilled: [...prev.fullfilled, index],
                listKeyDone: listKeyDone,
              };
            } else {
              const listKeyFailure = [...prev.rejected, index]
                .flatMap(i =>
                  getProductPriceKeys(i, prev.listKeyFailure, products)
                )
                .filter(key => key !== '');
              dispatch(setPrintError(curr.reason as ApiError));
              return {
                ...prev,
                rejected: [...prev.rejected, index],
                listKeyFailure,
              };
            }
          },
          {
            rejected: [],
            fullfilled: [],
            listKeyDone: [],
            listKeyFailure: [],
          }
        );

        if (
          promises.listKeyDone.length !== 0 ||
          promises.listKeyFailure.length !== 0
        ) {
          (async (): Promise<PayloadAction<Reprice | undefined | ApiError>> =>
            await dispatch(
              getProductsPrintStatus({
                requestBody: {
                  listKeyDone: promises.listKeyDone,
                  listKeyFailure: promises.listKeyFailure,
                  daysToSubract: REPRICE_DAYS_TO_SUBTRACT,
                  daysToSum: REPRICE_DAYS_TO_SUM,
                },
              })
            ))();
        }
      });
    } catch (err) {
      return rejectWithValue(err as ApiError);
    }
  }
);

export const repriceSlice = createSlice({
  name: 'reprice',
  initialState,
  reducers: {
    initReprice: () => initialState,
    setPrintError: (state, { payload }: PayloadAction<ApiError>) => {
      state.printHasError = payload;
    },
    setCurrentDateSlice: (state, { payload }: PayloadAction<string>) => {
      state.currentDate = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getRepriceBrands.pending, state => {
        state.dataIsLoading = true;
      })
      .addCase(getRepriceBrands.fulfilled, (state, { payload }) => {
        if (payload && payload.dateRepriceList) {
          state.data.dateRepriceList = payload.dateRepriceList;
        }
        state.dataIsLoading = false;
      })
      .addCase(getRepriceBrands.rejected, (state, { payload }) => {
        state.dataIsLoading = false;
        state.dataHasError = payload as ApiError;
      })
      .addCase(printRepriceProducts.pending, state => {
        state.printIsLoading = true;
      })
      .addCase(printRepriceProducts.fulfilled, state => {
        state.printIsLoading = false;
      })
      .addCase(printRepriceProducts.rejected, (state, { payload }) => {
        state.printIsLoading = false;
        state.printHasError = payload as ApiError;
      })
      .addCase(getProductsPrintStatus.pending, state => {
        state.dataIsLoading = true;
      })
      .addCase(getProductsPrintStatus.fulfilled, (state, { payload }) => {
        if (payload.dateRepriceList) {
          state.data.dateRepriceList = payload.dateRepriceList;
        }
        state.dataIsLoading = false;
      })
      .addCase(getProductsPrintStatus.rejected, (state, { payload }) => {
        state.dataIsLoading = false;
        state.dataHasError = payload as ApiError;
      });
  },
});

export const { initReprice, setPrintError, setCurrentDateSlice } =
  repriceSlice.actions;
export default repriceSlice.reducer;
