import { FC, memo, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Typography } from '@/components/ui/Typography';
import { UILoader } from '@/components/ui/Loader';

export interface IProductImageProps {
  brandCode?: string;
  modelCode?: string;
  colorCode?: string;
  imageUrl?: string;
  width: number;
  height?: number;
  lazyload?: boolean;
  className?: string;
  fluid?: boolean;
}

export interface IProductFallbackProps {
  width: number;
  height?: number;
  fluid?: boolean;
}

export interface IImageContainerProps {
  width?: number;
  height?: number;
  fluid?: boolean;
  className?: string;
}
export interface ILoaderContainerProps {
  visible: boolean;
  width?: number;
  height?: number;
  fluid?: boolean;
}

export interface IImageProps {
  fluid?: boolean;
  hidden?: boolean;
}

//#region - Styled Components
const StyledImageContainer = styled('div')<IImageContainerProps>`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: ${({ height }): string => (height ? `${height}px` : 'auto')};
  width: ${({ fluid, width }): string =>
    fluid ? '70vh' : width ? `${width}px` : 'auto'};
  margin: 0 auto;
`;

const StyledImage = styled('img')<IImageProps>`
  width: ${({ fluid, width }): string => (fluid ? '70vh' : `${width}px`)};
`;

const StyledFallback = styled('div')<IProductFallbackProps>`
  width: ${({ fluid, width }): string =>
    fluid ? '70vh' : width ? `${width}px` : 'auto'};
  height: ${({ height, width, fluid }): number | string =>
    height ? height : fluid ? '40vh' : `${(width * 2) / 3}px`};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLoaderContainer = styled('div')<ILoaderContainerProps>`
  visibility: ${({ visible }): string => (visible ? 'visible' : 'hidden')};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: ${({ height }): string => (height ? `${height}px` : 'auto')};
  width: ${({ fluid, width }): string =>
    fluid ? '70vh' : width ? `${width}px` : 'auto'};
`;

//#endregion

const ProductImage: FC<IProductImageProps> = memo(
  ({
    className,
    brandCode,
    height,
    lazyload = false,
    modelCode,
    width,
    fluid,
    imageUrl,
  }): JSX.Element => {
    const [imgUrl, setImgUrl] = useState('');
    const [imgSrcSet, setImgSrcSet] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [notExists, setNotExists] = useState(false);
    const [loaderSize, setLoaderSize] = useState(0);
    let thumbUrl = useRef<string>(
      process.env.REACT_APP_THUMB_URL || 'https://smartretail-im.luxottica.com'
    );

    useEffect((): (() => void) | void | void => {
      if (!imageUrl) {
        return;
      }

      if (imageUrl.startsWith('/cdn-')) {
        thumbUrl.current = 'https://smartretail-im2.luxottica.com';
      }

      const fullImageSrc = `${thumbUrl.current}/${imageUrl}`;

      setImgUrl(`${fullImageSrc}?imWidth=${width}`);
      setImgSrcSet(
        `${fullImageSrc}?imWidth=${width} 1x, ${fullImageSrc}?imWidth=${
          width * 2
        } 2x, ${fullImageSrc}?imWidth=${width * 3} 3x`
      );
      setLoaderSize(width <= 200 ? width / 5 : 40);

      return (): void => {
        setIsLoaded(false);
        setNotExists(false);
      };
    }, [imageUrl, width]);

    return (
      <StyledImageContainer
        className={className}
        width={width}
        height={height}
        fluid={fluid}
      >
        <StyledLoaderContainer
          visible={!isLoaded && !notExists}
          width={loaderSize}
          height={loaderSize}
        >
          <UILoader size={loaderSize} />
        </StyledLoaderContainer>
        <StyledImage
          style={
            !isLoaded
              ? {
                  width: 0,
                  height: 0,
                  overflow: 'hidden',
                  position: 'absolute',
                  top: '-9999px',
                  left: '-9999px',
                }
              : {}
          }
          alt={`${brandCode} ${modelCode}`}
          loading={lazyload ? 'lazy' : 'eager'}
          src={imgUrl}
          srcSet={imgSrcSet}
          width={width}
          onLoad={(): void => setIsLoaded(true)}
          onError={(): void => setNotExists(true)}
          fluid={fluid}
          hidden={!isLoaded}
        />

        {notExists ? (
          <StyledFallback
            style={isLoaded ? { display: 'none' } : {}}
            width={width}
            height={height}
            fluid={fluid}
          >
            <Typography as="div" size="xxs">
              NO IMAGE
            </Typography>
          </StyledFallback>
        ) : null}
      </StyledImageContainer>
    );
  }
);

ProductImage.displayName = 'ProductImage';

export default ProductImage;
